import {useTheme, List} from '@fluentui/react';
import {Link} from 'react-router-dom';
import {LoadWrapper} from 'components/LoadWrapper';
import {Pill} from 'components';
import {formatDateTime, DateTimeFormat} from 'i18n/localeDateFormat';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {getListClassNames} from 'styles';
import {
	ActivityListHeader,
	ActivityListHeaderProps,
} from './_ActivityListHeader';
import {useActivityListItems} from './useActivityListItems';
import {DashboardCardProps} from 'features/Dashboard/components';

type ActivityListProps = {
	title: string;
	showOpenInNewWindow?: ActivityListHeaderProps['showOpenInNewWindow'];
} & DashboardCardProps;

export const ActivityList: React.FC<ActivityListProps> = ({
	title,
	halfHeight = false,
	showOpenInNewWindow = false,
	itemCountLoaded,
}) => {
	const {i18n, t} = useTranslation(['common/enums'], {
		keyPrefix: 'RegulatoryDocumentStatus',
	});
	const theme = useTheme();
	const classNames = getListClassNames(theme, halfHeight);

	const {items, loading} = useActivityListItems();

	React.useEffect(() => {
		if (!loading && items) {
			itemCountLoaded?.(items.length);
		}
	}, [itemCountLoaded, items, loading]);

	const onRenderCell = (item: any): JSX.Element => {
		return (
			<div className={classNames.itemCell} data-is-focusable={true}>
				<div className={classNames.itemContent}>
					<Link to={item.url} className={classNames.link}>
						<div className={classNames.itemName}>{item.title}</div>
					</Link>
					<div className={classNames.itemSubtext}>
						{formatDateTime(
							new Date(item.modifiedAt),
							i18n,
							DateTimeFormat.DateMonthWeekday,
						)}
					</div>
				</div>
				<Pill className={classNames.itemPill}>{t(item.type)}</Pill>
			</div>
		);
	};

	return (
		<LoadWrapper loading={loading}>
			<div className={classNames.activityContainer}>
				<ActivityListHeader
					showOpenInNewWindow={showOpenInNewWindow}
					halfHeight={halfHeight}
					title={title}
				/>
				<div className={classNames.container} data-is-scrollable>
					<List items={items} onRenderCell={onRenderCell} />
				</div>
			</div>
		</LoadWrapper>
	);
};
