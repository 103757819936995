export const CLASS_TO_ADD_TO_PARAGRAPHS_SECTION_HEADER_WHEN_STICKY =
	'sticky-header-of-paragraphs-section';

export const HEIGHT_OF_PARAGRAPHS_SECTION_HEADER = 32;

export const KEYWORD_ASSIGNMENT_LINK =
	'https://porsche.sharepoint.com/sites/TRACE774/Shared%20Documents/Forms/AllItems.aspx?csf=1&web=1&e=FsRyX4&CID=c646efd0%2D83c4%2D4581%2Dbc3b%2D27b1076ce3cb&FolderCTID=0x012000A4CF1022B590A34F890CC70D77155B77&id=%2Fsites%2FTRACE774%2FShared%20Documents%2FGeneral%2FBetroffenheitsbewertung';

export const REGULATION_FORECAST_LINK =
	'https://porsche.sharepoint.com/sites/Vorschriftenprognose/Lists/ETB%20%20Vorschriftenprognose/Standard.aspx';
