import React, {useEffect} from 'react';
import {Stack, Text, useTheme} from '@fluentui/react';
import {useTranslation} from 'react-i18next';
import {LoadWrapper} from 'components/LoadWrapper';
import {mergeStyleSets} from '@fluentui/react';
import {useVkoKeywordsLazyQuery} from 'features/Dashboard/hooks/vkoKeywords.generated';

const VkoWorkbenchComponent: React.FC = () => {
	const {t} = useTranslation('features/dashboard', {
		keyPrefix: 'DashboardPage',
	});
	const theme = useTheme();
	const classNames = vkoClassNames(theme);

	const [getVkoKeywords, {loading: loadingVkoKeywords, data: vkoKeywords}] =
		useVkoKeywordsLazyQuery();

	useEffect(() => {
		getVkoKeywords().catch(error => {
			console.error('Error fetching Vko keywords:', error);
		});
	}, [getVkoKeywords]);

	const renderKeywords = () => {
		return vkoKeywords?.myVKOKeywords?.map(k => (
			<Text key={k.id} className={classNames.keyword}>
				{k.name}
			</Text>
		));
	};

	return (
		<Stack className={classNames.activityContainer}>
			<Stack horizontal horizontalAlign='start'>
				<Stack
					grow={1}
					styles={{
						root: {
							padding: 10,
						},
					}}
				>
					<Stack.Item>
						<Text variant='large'>{t('AssignedMainKeywords')}</Text>
					</Stack.Item>
					<Stack.Item
						styles={{
							root: {
								paddingTop: 10,
							},
						}}
					>
						<LoadWrapper loading={loadingVkoKeywords}>
							<div className={classNames.keywordWrapper}>
								{renderKeywords()}
							</div>
						</LoadWrapper>
					</Stack.Item>
				</Stack>
			</Stack>
		</Stack>
	);
};

const vkoClassNames = (theme: any) =>
	mergeStyleSets({
		activityContainer: {
			background: theme.palette.white,
			paddingTop: 10,
			margin: 10,
			borderRadius: 15,
		},
		keyword: {
			borderRadius: '10px',
			color: theme.palette.neutralSecondary,
			background: theme.palette.neutralLight,
			fontSize: 12,
			padding: '2px 7px',
			marginRight: 5,
			marginBottom: 10,
		},
		keywordWrapper: {
			display: 'flex !important',
			flexWrap: 'wrap',
		},
	});

export default VkoWorkbenchComponent;
