export const alphabeticalSort = (arrObjects: any[], propertyName: string) => {
	const objectsUnsorted = arrObjects ?? [];
	const objectsSorted = objectsUnsorted
		.slice()
		.sort((a, b) => a[propertyName].localeCompare(b[propertyName]));
	return objectsSorted;
};

export const sortObjectsByDate = (
	objects: any[],
	dateFieldPath: string,
	ascending = true,
): any[] => {
	return [...objects].sort((a, b) => {
		const dateA = dateFieldPath.split('.').reduce((obj, key) => obj?.[key], a);
		const dateB = dateFieldPath.split('.').reduce((obj, key) => obj?.[key], b);

		const timestampA = dateA ? new Date(dateA).getTime() : 0;
		const timestampB = dateB ? new Date(dateB).getTime() : 0;

		return ascending ? timestampA - timestampB : timestampB - timestampA;
	});
};
