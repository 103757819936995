import React from 'react';
import {Text} from '@fluentui/react';
import {v4 as uuidv4} from 'uuid';
import {GetMyKeywordsQuery} from 'authentication/hooks/myKeywords.generated';
import {GetMyVexClustersQuery} from 'authentication/hooks/myVexClusters.generated';
import {VkoKeywordsQuery} from 'features/Dashboard/hooks/vkoKeywords.generated';

export const useRenderedKeywords = (
	isVex: boolean,
	isVko: boolean,
	vexKeywords: GetMyKeywordsQuery | undefined,
	vkoKeywords: VkoKeywordsQuery | undefined,
	classNames: any,
) => {
	return React.useMemo(() => {
		if (isVex) {
			return vexKeywords?.myKeywords?.map(k => (
				<Text key={k.id || uuidv4()} className={classNames.keyword}>
					{k.name}
				</Text>
			));
		}

		if (isVko) {
			return vkoKeywords?.myVKOKeywords?.map(k => (
				<Text key={k.id || uuidv4()} className={classNames.keyword}>
					{k.name}
				</Text>
			));
		}

		return <></>;
	}, [isVex, isVko, vexKeywords, vkoKeywords, classNames.keyword]);
};

export const useRenderedVexClusters = (
	isVex: boolean,
	vexClusters: GetMyVexClustersQuery | undefined,
	classNames: any,
) => {
	return React.useMemo(() => {
		if (isVex) {
			return vexClusters?.myVexClusters?.map(k => (
				<Text key={uuidv4()} className={classNames.keyword}>
					{k.name}
				</Text>
			));
		}
		return <></>;
	}, [isVex, vexClusters, classNames.keyword]);
};
