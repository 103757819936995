import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMyRequirementsReviewQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMyRequirementsReviewQuery = { __typename?: 'Query', myRequirementsReview: Array<{ __typename?: 'Requirement', id: string, requirementId?: string | null, name: string, changeType: Types.RequirementChangeType, status: Types.RequirementStatus, createdAt?: any | null, modifiedAt?: any | null, vexClusters: Array<{ __typename?: 'VexCluster', id: string, name: string }>, approvalWorkFlow?: { __typename?: 'ApprovalWorkFlow', sentToApprovalTimestamp?: any | null, clearingTimestamp?: any | null } | null }> };


export const GetMyRequirementsReviewDocument = gql`
    query GetMyRequirementsReview {
  myRequirementsReview {
    id
    requirementId
    name
    changeType
    status
    createdAt
    modifiedAt
    vexClusters {
      id
      name
    }
    approvalWorkFlow {
      sentToApprovalTimestamp
      clearingTimestamp
    }
  }
}
    `;

/**
 * __useGetMyRequirementsReviewQuery__
 *
 * To run a query within a React component, call `useGetMyRequirementsReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyRequirementsReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyRequirementsReviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyRequirementsReviewQuery(baseOptions?: Apollo.QueryHookOptions<GetMyRequirementsReviewQuery, GetMyRequirementsReviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyRequirementsReviewQuery, GetMyRequirementsReviewQueryVariables>(GetMyRequirementsReviewDocument, options);
      }
export function useGetMyRequirementsReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyRequirementsReviewQuery, GetMyRequirementsReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyRequirementsReviewQuery, GetMyRequirementsReviewQueryVariables>(GetMyRequirementsReviewDocument, options);
        }
export type GetMyRequirementsReviewQueryHookResult = ReturnType<typeof useGetMyRequirementsReviewQuery>;
export type GetMyRequirementsReviewLazyQueryHookResult = ReturnType<typeof useGetMyRequirementsReviewLazyQuery>;
export type GetMyRequirementsReviewQueryResult = Apollo.QueryResult<GetMyRequirementsReviewQuery, GetMyRequirementsReviewQueryVariables>;