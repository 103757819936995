import {Stack, useTheme} from '@fluentui/react';
import {useTranslation} from 'react-i18next';
import {getDashboardClassNames} from './BaseWorkBenchComponent';
import {
	renderKeywordHeader,
	renderVexClusterHeader,
	renderRegulationsHeader,
	renderRequirementsHeader,
} from '..';
import {LoadWrapper} from 'components/LoadWrapper';
import {useAssignmentData} from 'features/Dashboard/hooks/useAssignmentData';
import {useRenderedKeywords, useRenderedVexClusters} from './AssignmentData';
import {useUserContext} from 'authentication/UserContext';
import React from 'react';

type SummaryItemsProps = {
	requirementCount: number | undefined;
};

export const SummaryComponent: React.FC<SummaryItemsProps> = ({
	requirementCount,
}) => {
	const {t} = useTranslation('features/dashboard', {
		keyPrefix: 'DashboardPage',
	});
	const theme = useTheme();
	const classNames = getDashboardClassNames(theme);
	const {isVex, isVko} = useUserContext();

	const {
		loadingVexKeywords,
		vexKeywords,
		loadingVexClusters,
		vexClusters,
		loadingVkoKeywords,
		vkoKeywords,
	} = useAssignmentData(isVex, isVko);

	const renderKeywords = useRenderedKeywords(
		isVex,
		isVko,
		vexKeywords,
		vkoKeywords,
		classNames,
	);

	const renderVexClusters = useRenderedVexClusters(
		isVex,
		vexClusters,
		classNames,
	);

	return (
		<>
			<Stack className={classNames.activityContainer}>
				<Stack horizontal tokens={{childrenGap: 20}} horizontalAlign='start'>
					<Stack
						grow={1}
						styles={{
							root: {
								padding: 10,
							},
						}}
					>
						<Stack.Item>{renderKeywordHeader({t, isVex, isVko})}</Stack.Item>
						<Stack.Item
							styles={{
								root: {
									paddingTop: 10,
								},
							}}
						>
							<LoadWrapper loading={loadingVexKeywords || loadingVkoKeywords}>
								<div className={classNames.keywordWrapper}>
									{renderKeywords}
								</div>
							</LoadWrapper>
						</Stack.Item>
					</Stack>
					<Stack
						grow={1}
						styles={{
							root: {
								padding: 10,
							},
						}}
					>
						<Stack.Item>{renderVexClusterHeader({t, isVex})}</Stack.Item>
						<Stack.Item
							styles={{
								root: {
									paddingTop: 10,
								},
							}}
						>
							<LoadWrapper loading={loadingVexClusters}>
								<div className={classNames.keywordWrapper}>
									{renderVexClusters}
								</div>
							</LoadWrapper>
						</Stack.Item>
					</Stack>
					<Stack
						grow={1}
						styles={{
							root: {
								padding: 10,
							},
						}}
					>
						<Stack.Item>{renderRequirementsHeader({t, isVex})}</Stack.Item>
						<Stack.Item
							styles={{
								root: {
									paddingTop: 10,
								},
							}}
						>
							<LoadWrapper loading={loadingVexClusters}>
								<div className={classNames.keywordWrapper}>
									{requirementCount}
								</div>
							</LoadWrapper>
						</Stack.Item>
					</Stack>
				</Stack>
			</Stack>
		</>
	);
};
