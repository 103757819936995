import {Dispatch, MutableRefObject, SetStateAction} from 'react';
import {QueryParagraph} from './RegDocDetailsPage.queryTypes';

/**
 * * State
 */

/**
 * Although the selected paragraphs context says otherwise, this is the correct
 * type for the selected paragraphs.
 */
export type SelectedParagraphs = QueryParagraph[];

export enum RegulatoryDetailsPageFormMode {
	None,
	Edit,
	EditMatKon,
	Assign,
	Remove,
	Compare,
}

export type SetIsFormRefetchingRegDoc = Dispatch<SetStateAction<boolean>>;

/*
 * Other
 */

export type RefToContainerOfMainParagraphs =
	MutableRefObject<HTMLDivElement | null>;
