import {useMemo} from 'react';
import {useUserContext} from 'authentication/UserContext';
import {useGetActivityItemsQuery} from './hooks/activityItems.generated';
import orderBy from 'lodash/orderBy';
import {ActivityListHelper} from './_ActivityListHelper';

export const useActivityListItems = () => {
	const {localAccountId} = useUserContext();

	const {loading, data} = useGetActivityItemsQuery({
		variables: {userId: localAccountId},
	});

	const regulatoryDocuments = data?.regulatoryDocuments?.nodes ?? [];
	const requirements = data?.requirements ?? [];
	const regulations = data?.userCreatedRegulations ?? [];
	const mergedItems = orderBy(
		[...regulatoryDocuments, ...regulations, ...requirements],
		'modifiedAt',
		'desc',
	);

	// Add "title" and "url" key to every mergedItems
	const sanitizedItems = mergedItems.map(item => {
		let title = item.name;
		let url = '';
		switch (item.type) {
			case 'Regulation':
				title = ActivityListHelper.getRegulationTitle(item);
				url = ActivityListHelper.getRegulationLink(item);
				break;
			// Every regulatoryDocument uses this pattern: NAME [REGULATION.NUMBER]
			case 'RegulatoryDocument':
				title = ActivityListHelper.getRegulatoryDocumentTitle(item);
				url = ActivityListHelper.getRegulatoryDocumentLink(item);
				break;
			case 'Requirement':
				title = ActivityListHelper.getRequirementTitle(item as any);
				url = ActivityListHelper.getRequirementLink(item as any);
				break;
			default:
				console.log('Element does not require a specific title');
		}

		return {
			title,
			url,
			...item,
		};
	});

	const items = useMemo(() => sanitizedItems, [data]);

	return {items, loading};
};
