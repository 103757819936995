import {useTranslation} from 'react-i18next';
import {getListClassNames} from 'styles';
import React from 'react';
import {useTheme} from '@fluentui/react';
import {LoadWrapper} from 'components/LoadWrapper';
import {WorkbenchHeader} from '../Headers';
import {EntityList, EntityListColumn} from 'components/EntityList';
import {DashboardTooltipTranslationProvider} from '../DashboardTooltipTranslationProvider';
import {useApproverTileColumns} from '../../hooks/useTileColumns';
import {TileContainer} from '../../hooks/useTileContainer';
import {useGetMyRequirementsReviewQuery} from '../../hooks/myRequirementsReview.generated';
import {RequirementStatus} from 'types';
import {DashboardCardProps} from '..';
import {sortObjectsByDate} from 'helpers/sortHelpers';

export const MyReviewItems: React.FC<DashboardCardProps> = ({
	halfHeight = false,
	itemCountLoaded,
}) => {
	const {t} = useTranslation('features/dashboard', {
		keyPrefix: 'ApproverOverview',
	});

	const {data, loading} = useGetMyRequirementsReviewQuery();
	const theme = useTheme();
	const classNames = getListClassNames(theme, halfHeight);

	const openReviews = React.useMemo(() => {
		return sortObjectsByDate(
			data?.myRequirementsReview?.filter(
				r => r.status === RequirementStatus.Approval,
			) ?? [],
			'approvalWorkFlow.sentToApprovalTimestamp',
			false,
		);
	}, [data]);

	const completedReviews = React.useMemo(() => {
		return sortObjectsByDate(
			data?.myRequirementsReview?.filter(
				r => r.status === RequirementStatus.Final,
			) ?? [],
			'approvalWorkFlow.clearingTimestamp',
			false,
		);
	}, [data]);

	React.useEffect(() => {
		if (!loading && data?.myRequirementsReview)
			itemCountLoaded?.(
				data?.myRequirementsReview?.filter(
					r =>
						r.status === RequirementStatus.Final ||
						r.status === RequirementStatus.Approval,
				).length,
			);
	}, [itemCountLoaded, data, loading]);

	const approverTileColumns: EntityListColumn[] = useApproverTileColumns();

	const RequirementsList = ({items}: any) => (
		<DashboardTooltipTranslationProvider>
			<EntityList
				sticky={false}
				selectionMode={0}
				items={items}
				columns={approverTileColumns}
			/>
		</DashboardTooltipTranslationProvider>
	);

	const visibleTiles = React.useMemo(() => {
		const tiles = [];
		if (loading) {
			tiles.push(<LoadWrapper loading={true} />);
			return tiles;
		}
		if (openReviews.length > 0) {
			tiles.push(
				<div className={classNames.activityContainer} key='openReviews'>
					<LoadWrapper loading={loading}>
						<WorkbenchHeader
							halfHeight
							title={t('OpenReviews')}
							items={openReviews.length}
						/>
						<div className={classNames.container} data-is-scrollable>
							<RequirementsList items={openReviews} />
						</div>
					</LoadWrapper>
				</div>,
			);
		}
		if (completedReviews.length > 0) {
			tiles.push(
				<div className={classNames.activityContainer} key='completedReviews'>
					<LoadWrapper loading={loading}>
						<WorkbenchHeader
							halfHeight
							title={t('CompletedReviews')}
							items={completedReviews.length}
						/>
						<div className={classNames.container} data-is-scrollable>
							<RequirementsList items={completedReviews} />
						</div>
					</LoadWrapper>
				</div>,
			);
		}
		return tiles;
	}, [openReviews, loading, t, classNames]);

	return <TileContainer visibleTiles={visibleTiles} />;
};
