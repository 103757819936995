import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMyRequirementsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMyRequirementsQuery = { __typename?: 'Query', myRequirements: Array<{ __typename?: 'Requirement', id: string, requirementId?: string | null, name: string, status: Types.RequirementStatus, version?: string | null, createdAt?: any | null, modifiedAt?: any | null, vexClusters: Array<{ __typename?: 'VexCluster', id: string, name: string }>, approvalWorkFlow?: { __typename?: 'ApprovalWorkFlow', sentToApprovalTimestamp?: any | null, clearingTimestamp?: any | null, approverRefs: Array<{ __typename?: 'User', name: string }> } | null }> };


export const GetMyRequirementsDocument = gql`
    query GetMyRequirements {
  myRequirements {
    id
    requirementId
    name
    status
    version
    createdAt
    modifiedAt
    vexClusters {
      id
      name
    }
    approvalWorkFlow {
      sentToApprovalTimestamp
      clearingTimestamp
      approverRefs {
        name
      }
    }
  }
}
    `;

/**
 * __useGetMyRequirementsQuery__
 *
 * To run a query within a React component, call `useGetMyRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyRequirementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyRequirementsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyRequirementsQuery, GetMyRequirementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyRequirementsQuery, GetMyRequirementsQueryVariables>(GetMyRequirementsDocument, options);
      }
export function useGetMyRequirementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyRequirementsQuery, GetMyRequirementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyRequirementsQuery, GetMyRequirementsQueryVariables>(GetMyRequirementsDocument, options);
        }
export type GetMyRequirementsQueryHookResult = ReturnType<typeof useGetMyRequirementsQuery>;
export type GetMyRequirementsLazyQueryHookResult = ReturnType<typeof useGetMyRequirementsLazyQuery>;
export type GetMyRequirementsQueryResult = Apollo.QueryResult<GetMyRequirementsQuery, GetMyRequirementsQueryVariables>;