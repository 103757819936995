import {DocumentNode, useApolloClient} from '@apollo/client';
import {
	DefaultButton,
	Dialog,
	DialogFooter,
	DialogType,
	Label,
	Panel,
	PanelType,
	PrimaryButton,
	Separator,
	Spinner,
	Stack,
	Toggle,
} from '@fluentui/react';
import {useUserContext} from 'authentication/UserContext';
import {MarketNamingTemplateField, RichtextDisplay} from 'components';
import {ConfirmDeleteDialog} from 'components/Dialogs';
import {
	ControlledDatePicker,
	ControlledEntityDropdown,
	ControlledSpinButton,
	ControlledTextField,
	EnumDropdown,
	ErrorMessage,
	ReferencePicker,
	RegulatoryDocumentsEnum,
} from 'components/hookForms';
import {
	FileUpload,
	FileWithMetadata,
} from 'components/hookForms/ControlledFileUpload';
import {ControlledRichtextEditor} from 'components/hookForms/ControlledRichtextEditor';
import {useGetConvolutesQuery} from 'features/AdminSection/hooks/convolutes.generated';
import {useGetGdprRuleQuery} from 'features/AdminSection/hooks/gdprRules.generated';
import {GetRegulationDocument} from 'features/Regulations/hooks';
import {convertToRef} from 'helpers';
import {useFileUpload} from 'hooks';
import omitDeep from 'omit-deep-lodash';
import React, {useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {
	ConvoluteType,
	Market,
	DocumentStatus,
	Origin,
	ReferenceType,
	RegulatoryDocument,
	RegulatoryDocumentFileType,
	RegulatoryDocumentStatus,
	TopicManagementSummary,
	TopicManagementSummaryQuarter,
	WorkflowStatus,
} from 'types';
import {
	useCheckVersionNameMutation,
	useCreateRegulatoryDocumentMutation,
	useDeleteRegulatoryDocumentMutation,
	useUpdateRegulatoryDocumentMutation,
	useUploadRegulatoryDocumentMutation,
} from '../../hooks';
import {useDoubletCheckRegulatoryDocumentFileMutation} from '../../hooks/doubletCheckRegulatoryDocumentFile.generated';
import {useUpdateRegulatoryDocumentAttachmentsMutation} from '../../hooks/updateRegulatoryDocumentAttachments.generated';
import {RegulatoryDocumentFormInfo} from './useRegulatoryDocumentForm';
import {RegulatoryDocumentFormMode} from './useRegulatoryDocumentFormMode';
import {useDismantleDocumentMutation} from '../../hooks/useDismantleDocument.generated';
/**
 * Start of imports we should be careful with:
 *
 * Import these directly instead of from RegDocDetailsPage to prevent circular
 * imports.
 */
import {
	LabelWithTooltip,
	ProviderThatEnablesGettingTooltipsFromContext,
	createSubfieldTranslationKey,
} from 'features/localizedTooltips';
import {evictAuditLogs} from '../../RegDocDetailsPage/evictAuditLogs';
import {RegDocAuditLogsInfo} from '../../RegDocDetailsPage/useGetRegDocAuditLogs';
import {useRegDocsFormTranslations} from '../../hooks/RegulatoryDocuments.commonHooks';
import {useRegulatoryDocumentFormDataQuery} from '../../hooks/useRegulatoryDocumentFormData.generated';
import {RegDocsTooltipTranslationProvider} from '../RegDocTooltipTranslationProvider';
import {
	PredecessorOrSuccessor,
	PredecessorOrSuccessorProps,
} from './PredecessorOrSuccessor';
import {RegulationChangeDialog} from './RegulationChangeDialog';
import {
	BasicDocFields,
	FormRegulatoryDoc,
} from './RegulatoryDocumentsForm.types';
import {
	getValueForPredecessorOrSuccessor,
	mapDocumentReferences,
} from 'features/RegulatoryDocuments/regulatoryUtils';
import {DocumentSourceEnum} from 'features/Regulations/RegulationDetail/RegulationDetail.types';

/**
 * End of imports we should be careful with.
 */

type StartPolling = (pollInterval: number) => void;

export interface RegulatoryDocumentsFormProps
	extends RegulatoryDocumentFormInfo {
	regulatoryDocuments: BasicDocFields[];
	regulatoryDocument: FormRegulatoryDoc;
	markets: Market[];
	regulationNumber: string;
	regulationId: string | null;
	startPolling: StartPolling | null;
	queriesToRefetchAfterSave: DocumentNode[];
	fetchAndSetAuditLogs?: RegDocAuditLogsInfo['refetchAuditLogsAndUpdateIsLoading'];
}

export const RegulatoryDocumentsForm = ({
	regulationId,
	regulationNumber,
	regulatoryDocument,
	regulatoryDocuments,
	markets,
	startPolling,
	dialogStateInfo: {dialogState, hideDialog},
	documentChangeStateInfo: {didDocumentChange, setDidDocumentChange},
	modeStateInfo: {setMode, mode},
	queriesToRefetchAfterSave: refetchQueries,
	fetchAndSetAuditLogs,
}: RegulatoryDocumentsFormProps) => {
	const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
	const [dialogValues, setDialogValues] = useState<any>({});

	/**
	 * Mutations
	 */
	const [createRegulatoryDocument] = useCreateRegulatoryDocumentMutation();
	const [updateRegulatoryDocument] = useUpdateRegulatoryDocumentMutation();
	const [deleteRegulatoryDocument] = useDeleteRegulatoryDocumentMutation();
	const [uploadRegulatoryDocument] = useUploadRegulatoryDocumentMutation();
	const [updateRegulatoryDocumentAttachments] =
		useUpdateRegulatoryDocumentAttachmentsMutation();
	const [checkForDoublettes] = useDoubletCheckRegulatoryDocumentFileMutation();
	const [checkNaming] = useCheckVersionNameMutation();

	/**
	 * Queries
	 */

	const {data: regDocFormData} = useRegulatoryDocumentFormDataQuery();
	const documentSources = React.useMemo(
		() =>
			regDocFormData?.documentSources?.filter(
				r => r.name?.toLowerCase() !== DocumentSourceEnum.Pogis.toLowerCase(),
			) ?? [],
		[regDocFormData],
	);

	/**
	 * Other hooks
	 */
	const {t} = useRegDocsFormTranslations();

	const apolloClient = useApolloClient();

	/**
	 * GDRP
	 */
	const {data: convolutes} = useGetConvolutesQuery();

	const convolute = React.useMemo(
		() =>
			convolutes?.convolutes?.find(
				c => c.convoluteType === ConvoluteType.RegulatoryDocument,
			),
		[convolutes],
	);

	const {data: gdprRule} = useGetGdprRuleQuery({
		variables: {
			id: convolute?.gdprRule?.id || '',
		},
	});

	const rule = React.useMemo(() => gdprRule?.gdprRule, [gdprRule]);

	/**
	 * Other hooks
	 */
	const {isVko, isAdmin, encodedUserId} = useUserContext();

	const [dismantleDocument] = useDismantleDocumentMutation();
	const [showTopicManagementSummaryState, setshowTopicManagementSummaryState] =
		React.useState<boolean>(
			Boolean(regulatoryDocument?.topicManagementSummary),
		);
	const [boolDismantleDoc, setDismantleDoc] = React.useState<boolean>(false);
	const [boolDismantleDocDisable, setDismantleDocDisable] =
		React.useState<boolean>(false);
	const [doubletError, setDoubletError] = React.useState<boolean>(false);
	const [doubletMatches, setDoubletMatches] = React.useState<
		RegulatoryDocument[]
	>([]);
	const [namingError, setNamingError] = React.useState<boolean>(false);
	const [isDocumentUploading, setIsDocumentUploading] =
		React.useState<boolean>(false);
	const [isAttachmentsUploading, setIsAttachmentsUploading] =
		React.useState<boolean>(false);

	const defaultDocument = React.useMemo(
		() =>
			regulatoryDocument?.document
				? [
						{
							name: regulatoryDocument.name ?? '',
							blob: regulatoryDocument.document,
						},
				  ]
				: [],
		[regulatoryDocument?.document],
	);

	const {attachments, FileUploadComponent} = useFileUpload(
		(regulatoryDocument ?? undefined) as any,
		{
			enableVkoOnlyOption: true,
		},
	);

	const [documentToUpload, setDocumentToUpload] = React.useState<
		File | undefined
	>(undefined);

	const [documentFileType, setDocumentFileType] = React.useState<
		RegulatoryDocumentFileType | undefined
	>();

	const {handleSubmit, control, reset, formState, watch, getValues} =
		useForm<RegulatoryDocument>({
			reValidateMode: 'onSubmit',
			mode: 'onBlur',
		});

	React.useEffect(() => {
		if (regulatoryDocument) {
			if (mode === RegulatoryDocumentFormMode.Copy) {
				reset({
					summary: regulatoryDocument.summary,
					regulation: regulatoryDocument.regulation,
					topicManagementSummary: regulatoryDocument.topicManagementSummary,
				});
			} else {
				reset(regulatoryDocument);
			}
		} else {
			reset({});
		}

		setshowTopicManagementSummaryState(
			Boolean(regulatoryDocument?.topicManagementSummary),
		);
	}, [regulatoryDocument, mode]);

	const onDocumentUploadChange = React.useCallback(
		(files: FileWithMetadata[]) => {
			const newDocument = files[0]?.file;
			setDocumentToUpload(newDocument);

			if (newDocument) {
				setDidDocumentChange(true);

				if (newDocument.name.endsWith('.reqifz')) {
					setDocumentFileType(RegulatoryDocumentFileType.ReqIf);
					setDismantleDoc(true);
					setDismantleDocDisable(true);
				} else {
					setDocumentFileType(RegulatoryDocumentFileType.Pdf);
					setDismantleDoc(false);
					setDismantleDocDisable(false);
				}
			} else {
				setDismantleDoc(false);
				setDismantleDocDisable(true);
			}
		},
		[],
	);

	const onRegulatoryDocumentSaved = React.useCallback(
		async (regDocId?: string) => {
			if (regDocId && didDocumentChange && documentToUpload) {
				setIsDocumentUploading(true);
				await uploadRegulatoryDocument({
					variables: {
						input: {
							regulatoryDocumentId: regDocId,
							file: documentToUpload,
							fileType: documentFileType,
							contentType: documentToUpload.type ?? 'application/octet-stream',
						},
					},
				});
				setIsDocumentUploading(false);

				if (documentFileType === RegulatoryDocumentFileType.Pdf) {
					dismantleDocument({
						variables: {
							input: regDocId,
							fastTrack: !boolDismantleDoc,
						},
					});
				}
			}

			if (regDocId && attachments) {
				setIsAttachmentsUploading(true);
				await updateRegulatoryDocumentAttachments({
					variables: {
						input: {
							regulatoryDocumentId: regDocId,
							attachments: attachments.map(f => ({
								attachmentId: f.file.name,
								file: f.file,
								categoryId: f.attachmentCategoryId,
								isVkoOnly: f.isVkoOnly,
							})),
						},
					},
				});
				setIsAttachmentsUploading(false);
			}

			apolloClient.refetchQueries({include: refetchQueries});
			setMode(RegulatoryDocumentFormMode.None);
			startPolling?.(10000);
		},
		[
			didDocumentChange,
			documentToUpload,
			attachments,
			apolloClient,
			documentFileType,
			setMode,
			startPolling,
			boolDismantleDoc,
		],
	);

	const getCreateDocumentInput = (data: RegulatoryDocument) => {
		return {
			input: {
				summary: data.summary,
				regulationRef: regulationId ? {id: regulationId} : undefined,
				name: data.name,
				status: data.status,
				topicManagementSummary:
					data.topicManagementSummary?.quarter &&
					data.topicManagementSummary?.year &&
					showTopicManagementSummaryState
						? {
								quarter: data.topicManagementSummary?.quarter,
								year: data.topicManagementSummary?.year,
						  }
						: null,
				predecessorRef: getValueForPredecessorOrSuccessor(data.predecessor),
				successorRef: getValueForPredecessorOrSuccessor(data.successor),
				documentSourceRef: convertToRef(data.documentSource),
				origin: isVko ? Origin.Internal : Origin.External,
				workflow: {
					status: WorkflowStatus.New,
				},
				dateEffective: data.dateEffective,
				dateExpiration: data.dateExpiration,
				willBeAnonymized: rule?.anonymize || false,
				deletionPeriod: rule?.deletionPeriod || 0,
				documentReferences: mapDocumentReferences(data.documentReferences),
			},
		};
	};

	const getUpdateInput = (
		data: RegulatoryDocument,
		regulatoryDocument: FormRegulatoryDoc,
	) => {
		return {
			input: {
				id: regulatoryDocument?.id || '',
				regulationRef: regulationId ? {id: regulationId} : undefined,
				name: data.name,
				status: data.status,
				summary: data.summary,
				workflow: omitDeep(data.workflow, '__typename') as any,

				topicManagementSummary:
					data.topicManagementSummary?.quarter &&
					data.topicManagementSummary?.year &&
					showTopicManagementSummaryState
						? {
								quarter: data.topicManagementSummary?.quarter,
								year: data.topicManagementSummary?.year,
						  }
						: null,
				predecessorRef: getValueForPredecessorOrSuccessor(data.predecessor),
				successorRef: getValueForPredecessorOrSuccessor(data.successor),
				documentSourceRef: convertToRef(data.documentSource),
				dateEffective: data.dateEffective,
				dateExpiration: data.dateExpiration,
				documentStatus: regulatoryDocument?.documentStatus,
				documentReferences: mapDocumentReferences(data.documentReferences),
			},
			reapprovingVKO:
				data.workflow?.reapprovingVKO?.id === encodedUserId
					? {id: encodedUserId}
					: null,
		};
	};

	const handleSaveClick = React.useCallback(
		(doubletCircumvent?: boolean) => {
			if (
				regulatoryDocument?.workflow?.status === WorkflowStatus.Finalized &&
				mode === RegulatoryDocumentFormMode.Update
			) {
				setShowConfirmationDialog(true);
				setDialogValues(getValues());
			} else {
				handleSubmit(async data => {
					let isDoublette = false;
					let isNameTaken = false;
					if (documentToUpload && didDocumentChange && !doubletCircumvent) {
						const doubletteResult = await checkForDoublettes({
							variables: {
								input: {
									file: documentToUpload,
								},
							},
						});

						if (
							doubletteResult?.data?.doubletcheckRegulatoryDocumentFile
								.regulatoryDocument &&
							doubletteResult?.data?.doubletcheckRegulatoryDocumentFile
								.regulatoryDocument.length > 0
						) {
							isDoublette = true;
							setDoubletMatches(
								doubletteResult?.data?.doubletcheckRegulatoryDocumentFile
									.regulatoryDocument as RegulatoryDocument[],
							);
						} else {
							isDoublette = false;
							setDoubletMatches([]);
						}
					}
					setDoubletError(isDoublette);

					if (mode !== RegulatoryDocumentFormMode.Update && regulationId) {
						const namingResult = await checkNaming({
							variables: {
								input: {
									regulationId,
									regulatoryDocumentName: data.name,
								},
							},
						});
						isNameTaken =
							namingResult?.data?.checkVersionNameRegulatoryDocument?.boolean ??
							false;
						setNamingError(isNameTaken);
					}

					if (isDoublette || isNameTaken) {
						return;
					}

					switch (mode) {
						case RegulatoryDocumentFormMode.Copy:
						case RegulatoryDocumentFormMode.Create:
							await createRegulatoryDocument({
								variables: getCreateDocumentInput(data),
							}).then(result => {
								const regDocId =
									result.data?.createRegulatoryDocument.regulatoryDocument.id;
								onRegulatoryDocumentSaved(regDocId);
							});
							break;
						case RegulatoryDocumentFormMode.Update:
							if (!regulatoryDocument?.id) {
								break;
							}

							await updateRegulatoryDocument({
								variables: getUpdateInput(data, regulatoryDocument),
								update: evictAuditLogs,
							}).then(result => {
								const regDocId =
									result.data?.updateRegulatoryDocument?.regulatoryDocument?.id;
								onRegulatoryDocumentSaved(regDocId);
							});
							await fetchAndSetAuditLogs?.();
							break;

						default:
							break;
					}
				})();
			}

			// /* eslint-disable complexity */
		},
		[
			mode,
			attachments,
			showTopicManagementSummaryState,
			namingError,
			doubletError,
			didDocumentChange,
			documentToUpload,
			isVko,
			rule,
			startPolling,
			onRegulatoryDocumentSaved,
		],
	);

	const handleCancelClick = React.useCallback(() => {
		setDoubletError(false);
		setNamingError(false);
		/**
		 * We must start polling because polling is paused whenever we open the form.
		 */
		startPolling?.(10000);

		setMode(RegulatoryDocumentFormMode.None);
	}, [startPolling]);

	const handleCloseButton = React.useCallback(() => {
		if (!showConfirmationDialog) {
			setDoubletError(false);
			setNamingError(false);
			/**
			 * We must start polling because polling is paused whenever we open the form.
			 */
			startPolling?.(10000);

			setMode(RegulatoryDocumentFormMode.None);
		}
	}, [startPolling, showConfirmationDialog]);

	const handleDeleteClick = React.useCallback(() => {
		if (regulatoryDocument?.id) {
			deleteRegulatoryDocument({
				variables: {
					input: {
						id: regulatoryDocument.id,
					},
				},
				refetchQueries: [GetRegulationDocument],
			});
		}

		hideDialog();
	}, [regulatoryDocument]);

	const RegulatoryDocumentDetails =
		mode === RegulatoryDocumentFormMode.Update
			? t('RegulatoryFormUpdate')
			: mode === RegulatoryDocumentFormMode.Copy
			? t('RegulatoryFormCopy')
			: t('RegulatoryFormViewHeader');

	const onRenderFooterContent: any = () => {
		if (isDocumentUploading) {
			return <Spinner label={t('DocumentUploading')} />;
		}

		if (isAttachmentsUploading) {
			return <Spinner label={t('AttachmentsUploading')} />;
		}

		return (
			<form>
				<PrimaryButton
					styles={buttonStyles}
					onClick={() => handleSaveClick()}
					disabled={formState.isSubmitting}
				>
					{mode === RegulatoryDocumentFormMode.Create ||
					mode === RegulatoryDocumentFormMode.Copy
						? t('CreateButton')
						: t('UpdateButton')}
				</PrimaryButton>
				<DefaultButton
					onClick={handleCancelClick}
					disabled={formState.isSubmitting}
				>
					{t('CancelButton')}
				</DefaultButton>
			</form>
		);
	};

	const DownloadButton = () =>
		regulatoryDocument?.document === undefined ? (
			<Label>{t('NoDocumentLabel')}</Label>
		) : (
			<PrimaryButton
				styles={buttonStyles}
				href={regulatoryDocument?.document?.uri}
			>
				{t('DownloadDocumentButton')}
			</PrimaryButton>
		);

	const createTopicManagementTranslationKey = (
		fieldPath: keyof TopicManagementSummary,
	): string => {
		return createSubfieldTranslationKey(`topicManagementSummary.${fieldPath}`);
	};

	type PropsForPredecessorAndSuccessor = Pick<
		PredecessorOrSuccessorProps,
		'control' | 'regulatoryDocuments' | 'regulatoryDocument' | 'watch'
	>;

	const dialogContentProps = {
		type: DialogType.normal,
		title: t('DoubletTitle'),
		subText: t('DoubletText'),
	};

	const propsForPredecessorAndSuccessor =
		useMemo((): PropsForPredecessorAndSuccessor => {
			return {
				control,
				regulatoryDocuments,
				regulatoryDocument,
				watch,
			};
		}, [control, regulatoryDocuments, regulatoryDocument, watch]);
	return (
		<>
			<Panel
				type={PanelType.medium}
				isOpen={mode !== RegulatoryDocumentFormMode.None}
				onDismiss={handleCloseButton}
				closeButtonAriaLabel='Close'
				headerText={
					mode === RegulatoryDocumentFormMode.Create
						? t('CreateRegulatoryDocumentPanel')
						: `${RegulatoryDocumentDetails}`
				}
				onRenderFooterContent={
					mode === RegulatoryDocumentFormMode.View
						? null
						: onRenderFooterContent
				}
				isFooterAtBottom={true}
			>
				{mode === RegulatoryDocumentFormMode.View ? (
					<div>
						<Label>
							{t('VersionLabel', {
								selectedRegulatoryDocumentVersion: regulatoryDocument?.name,
							})}
						</Label>
						<Separator />
						<Label>
							{t('StatusLabel', {
								selectedRegulatoryDocumentStatus: regulatoryDocument?.status,
							})}
						</Label>
						<Label>{t('SummaryLabel', {})}</Label>
						<RichtextDisplay value={regulatoryDocument?.summary ?? ''} />
						<Separator />
						<DownloadButton />
					</div>
				) : (
					<div>
						<Dialog
							dialogContentProps={dialogContentProps}
							hidden={doubletMatches.length === 0}
						>
							<ul key={'doubletMatches'}>
								{doubletMatches.map(x => (
									<li key={'itemDoublet' + x.id}>
										<a key={'linkDoublet' + x.id} href={x.regulation?.id ?? ''}>
											{(x.regulation?.regulationNumber ?? '') + ' | ' + x.name}
										</a>
									</li>
								))}
							</ul>
							<DialogFooter>
								<PrimaryButton
									text='Dokument hochladen'
									onClick={() => {
										handleSaveClick(true);
										setDoubletMatches([]);
									}}
								/>
								<DefaultButton
									text='Abbrechen'
									onClick={() => setDoubletMatches([])}
								/>
							</DialogFooter>
						</Dialog>
						<RegDocsTooltipTranslationProvider>
							<ProviderThatEnablesGettingTooltipsFromContext>
								<Stack tokens={tokens}>
									<MarketNamingTemplateField
										markets={markets}
										namingHint={t('RegulatoryDocumentNameNamingHint')}
										templatesToUse='regulatoryDocumentNamingTemplates'
									>
										{({validateFieldValue, isMarketSelected, placeholder}) => (
											<ControlledTextField
												prefix={regulationNumber}
												required={true}
												label={t('VersionFieldLabel')}
												control={control}
												name={'name'}
												rules={{
													required: t('VersionRequired'),
													validate: validateFieldValue,
												}}
												placeholder={placeholder}
												disabled={!isMarketSelected}
											/>
										)}
									</MarketNamingTemplateField>
									{namingError && (
										<ErrorMessage
											error={{message: t('NamingError'), type: 'NamingError'}}
										/>
									)}
									<ControlledEntityDropdown
										selectableOptions={documentSources}
										name={'documentSource'}
										label={t('DocumentSourceFieldLabel')}
										control={control}
										getKey={item => item.id}
										getText={item => item.name}
									/>
									<EnumDropdown
										required={true}
										label={t('StatusFieldLabel')}
										control={control}
										name={'status'}
										placeholder={t('StatusPlaceholder')}
										rules={{required: t('StatusRequired')}}
										enumName={'RegulatoryDocumentStatus'}
										enumType={RegulatoryDocumentStatus}
									/>
									<ControlledRichtextEditor
										label={t('SummaryFieldLabel')}
										control={control}
										name={'summary'}
									/>
									<Stack horizontal tokens={tokens}>
										<Stack.Item grow={1} styles={stackItemStyles}>
											<ControlledDatePicker
												control={control}
												name={'dateEffective'}
												label={t('DateEffectiveDatePicker')}
											/>
										</Stack.Item>
										<Stack.Item grow={1} styles={stackItemStyles}>
											<ControlledDatePicker
												control={control}
												name={'dateExpiration'}
												label={t('DateExpirationDatePicker')}
											/>
										</Stack.Item>
									</Stack>
									{mode === RegulatoryDocumentFormMode.Update && isAdmin && (
										<Toggle
											label={t('TopicManagementSummaryLabel')}
											inlineLabel
											defaultChecked={showTopicManagementSummaryState}
											onChange={(_, checked) =>
												setshowTopicManagementSummaryState(checked || false)
											}
										/>
									)}
									{showTopicManagementSummaryState && (
										<Stack horizontal tokens={tokens}>
											<Stack.Item grow={1} styles={stackItemStyles}>
												<EnumDropdown
													control={control}
													label={t('TopicManagementSummaryQuarterLabel')}
													name={'topicManagementSummary.quarter'}
													enumType={TopicManagementSummaryQuarter}
													enumName={'Quarters'}
													tooltipHostProps={{
														translationKey:
															createTopicManagementTranslationKey('quarter'),
													}}
												/>
											</Stack.Item>
											<Stack.Item grow={1} styles={stackItemStyles}>
												<ControlledSpinButton
													control={control}
													label={t('TopicManagementSummaryYearLabel')}
													name={'topicManagementSummary.year'}
													max={2299}
													min={2000}
													tooltipHostProps={{
														translationKey:
															createTopicManagementTranslationKey('year'),
													}}
												/>
											</Stack.Item>
										</Stack>
									)}
									{regulatoryDocuments.length > 0 && (
										<>
											<PredecessorOrSuccessor
												label={t('Predecessor')}
												name={'predecessor'}
												{...propsForPredecessorAndSuccessor}
											/>
											<PredecessorOrSuccessor
												label={t('Successor')}
												name={'successor'}
												{...propsForPredecessorAndSuccessor}
											/>
										</>
									)}
									<ReferencePicker
										control={control}
										name={'documentReferences'}
										label={t('ReferencesPickerLabel')}
										enumType={RegulatoryDocumentsEnum}
										referenceTypes={[
											ReferenceType.Regulation,
											ReferenceType.RegulatoryDocument,
										]}
									/>
								</Stack>
								<Separator />
								<LabelWithTooltip translationKey='document'>
									{t('DocumentFieldLabel')}
								</LabelWithTooltip>
								{!(
									regulatoryDocument?.documentStatus ===
									DocumentStatus.SplittingFinished
								) && (
									<>
										<Separator />
										<LabelWithTooltip translationKey='document'>
											{t('DocumentFieldLabel')}
										</LabelWithTooltip>
										<FileUpload
											renderCategory={false}
											onChange={onDocumentUploadChange}
											defaultFiles={defaultDocument}
											pdfOnly
											multiple={false}
											maxFiles={1}
										/>

										<Toggle
											label={t('DismantleDoc')}
											inlineLabel
											onText={t('DismantleDocYes')}
											offText={t('DismantleDocNo')}
											checked={boolDismantleDoc}
											onChange={(_, checked) =>
												setDismantleDoc(checked || false)
											}
											disabled={
												!(
													documentFileType === RegulatoryDocumentFileType.Pdf ||
													((regulatoryDocument &&
														regulatoryDocument.document &&
														regulatoryDocument.document.contentType ===
															'application/pdf') as boolean)
												) || boolDismantleDocDisable
											}
										/>
									</>
								)}
								{doubletError && (
									<ErrorMessage
										error={{message: t('DoubletteError'), type: 'DoubletError'}}
									/>
								)}
								<Separator />
								<LabelWithTooltip translationKey='attachments'>
									{t('AttachmentsFieldLabel')}
								</LabelWithTooltip>
								<FileUploadComponent />
							</ProviderThatEnablesGettingTooltipsFromContext>
						</RegDocsTooltipTranslationProvider>
					</div>
				)}
			</Panel>

			<RegulationChangeDialog
				data={dialogValues}
				showConfirmationDialog={showConfirmationDialog}
				setShowConfirmationDialog={setShowConfirmationDialog}
				regulatoryDocument={regulatoryDocument}
				regulationId={regulationId}
				fetchAndSetAuditLogs={fetchAndSetAuditLogs}
				showTopicManagementSummaryState={showTopicManagementSummaryState}
				onRegulatoryDocumentSaved={onRegulatoryDocumentSaved}
				isVko={isVko}
				rule={rule}
				setMode={setMode}
			/>

			<ConfirmDeleteDialog {...dialogState} onConfirm={handleDeleteClick} />
		</>
	);
};

const buttonStyles = {
	root: {
		marginRight: 8,
	},
};

const stackItemStyles = {
	root: {width: '100%'},
};

const tokens = {
	childrenGap: '5px',
};
