import {Stack, useTheme} from '@fluentui/react';
import React from 'react';
import {getListClassNames} from 'styles';
import {WorkbenchItems} from './WorkbenchItems';

type WorkbenchListProps = {
	halfHeight?: boolean;
};

export const VkoWorkbenchList: React.FC<WorkbenchListProps> = ({
	halfHeight = false,
}) => {
	const theme = useTheme();
	const classNames = getListClassNames(theme, halfHeight);
	return (
		<Stack.Item grow={1}>
			<div className={classNames.activityContainer}>
				<WorkbenchItems halfHeight />
			</div>
		</Stack.Item>
	);
};
