import {GetMyRequirementsQuery} from 'features/Dashboard/hooks/myRequirements.generated';
import {RequirementStatus} from 'types';

export const MyRequirementsMockup: GetMyRequirementsQuery = {
	myRequirements: [
		{
			id: 'UmVxdWlyZW1lbnQKZDY3MGU0NjI0YmQxZGNlY2Vj1234567890==',
			requirementId: 'R-10001',
			name: 'Mockup Blue Req 01',
			status: RequirementStatus.Approval,
			version: 'V1.0',
			createdAt: '2024-10-15T10:38:28.027Z',
			modifiedAt: '2024-11-13T11:48:24.937Z',
			vexClusters: [
				{
					id: 'VmV4Q2x1c3RlcgpkNjcwZTQwNjNiZDFkY2VjZWMzZTdjMzIz',
					name: 'Pablo VEX-Cluster',
					__typename: 'VexCluster',
				},
			],
			__typename: 'Requirement',
		},
		{
			id: 'UmVxdWlyZW1lbnQKZDY3MGU0NjI0YmQxZGNlY2Vj1234567890==',
			requirementId: 'R-10001',
			name: 'Mockup Blue Req 02',
			status: RequirementStatus.Approval,
			version: 'V1.0',
			createdAt: '2024-10-15T10:38:28.027Z',
			modifiedAt: '2024-11-13T11:48:24.937Z',
			vexClusters: [
				{
					id: 'VmV4Q2x1c3RlcgpkNjcwZTQwNjNiZDFkY2VjZWMzZTdjMzIz',
					name: 'Pablo VEX-Cluster',
					__typename: 'VexCluster',
				},
			],
			__typename: 'Requirement',
		},
		{
			id: 'UmVxdWlyZW1lbnQKZDY3MGU0NjI0YmQxZGNlY2Vj1234567890==',
			requirementId: 'R-10001',
			name: 'Mockup Red Req 01',
			status: RequirementStatus.Draft,
			version: 'V1.0',
			createdAt: '2024-10-15T10:38:28.027Z',
			modifiedAt: '2024-11-13T11:48:24.937Z',
			vexClusters: [
				{
					id: 'VmV4Q2x1c3RlcgpkNjcwZTQwNjNiZDFkY2VjZWMzZTdjMzIz',
					name: 'Pablo VEX-Cluster',
					__typename: 'VexCluster',
				},
			],
			__typename: 'Requirement',
		},
		{
			id: 'UmVxdWlyZW1lbnQKZDY3MGU0NjI0YmQxZGNlY2Vj1234567890==',
			requirementId: 'R-10001',
			name: 'Mockup Red Req 02',
			status: RequirementStatus.Draft,
			version: 'V1.0',
			createdAt: '2024-10-15T10:38:28.027Z',
			modifiedAt: '2024-11-13T11:48:24.937Z',
			vexClusters: [
				{
					id: 'VmV4Q2x1c3RlcgpkNjcwZTQwNjNiZDFkY2VjZWMzZTdjMzIz',
					name: 'Pablo VEX-Cluster',
					__typename: 'VexCluster',
				},
			],
			__typename: 'Requirement',
		},
		{
			id: 'UmVxdWlyZW1lbnQKZDY3MGU0NjI0YmQxZGNlY2Vj1234567890==',
			requirementId: 'R-10001',
			name: 'Mockup Green Req',
			status: RequirementStatus.Final,
			version: 'V1.0',
			createdAt: '2024-10-15T10:38:28.027Z',
			modifiedAt: '2024-11-13T11:48:24.937Z',
			vexClusters: [
				{
					id: 'VmV4Q2x1c3RlcgpkNjcwZTQwNjNiZDFkY2VjZWMzZTdjMzIz',
					name: 'Pablo VEX-Cluster',
					__typename: 'VexCluster',
				},
			],
			__typename: 'Requirement',
		},
	],
};
