import {useTheme, Stack, FontIcon, Text} from '@fluentui/react';
import {getListClassNames} from 'styles';
import {TFunction} from 'i18next';
import React from 'react';
import {getDashboardClassNames} from './Common/BaseWorkBenchComponent';

export const WorkbenchHeader = (props: any) => {
	const theme = useTheme();
	const classNames = getListClassNames(theme, props.halfHeight);

	return (
		<Stack horizontal horizontalAlign='space-between'>
			<Stack horizontal>
				<Stack.Item>
					<Text className={classNames.workbench}>{props.title}</Text>
				</Stack.Item>
				<Stack.Item>
					<Text className={classNames.itemCount}>{props.items}</Text>
				</Stack.Item>
			</Stack>
			<Stack>
				<FontIcon
					iconName='OpenInNewWindow'
					className={classNames.iconStyles}
				/>
			</Stack>
		</Stack>
	);
};

export const NewsItemsHeader = (props: any) => {
	const theme = useTheme();
	const classNames = getListClassNames(theme, props.halfHeight);

	return (
		<div>
			<Stack horizontal horizontalAlign='space-between'>
				<Stack.Item>
					<Text className={classNames.activity}>{props.title}</Text>
				</Stack.Item>
				<Stack.Item>
					<FontIcon
						iconName='OpenInNewWindow'
						className={classNames.iconStyles}
					/>
				</Stack.Item>
			</Stack>
		</div>
	);
};

type headerProps = {
	t: TFunction;
	isVex?: boolean;
	isVko?: boolean;
};

export const renderKeywordHeader = ({
	t,
	isVex = false,
	isVko = false,
}: headerProps): JSX.Element | undefined => {
	if (isVex) {
		return <Text variant='large'>{t('AssignedKeywords') as string}</Text>;
	}

	if (isVko) {
		return <Text variant='large'>{t('AssignedMainKeywords') as string}</Text>;
	}

	return undefined;
};

export const renderVexClusterHeader = ({
	t,
	isVex = false,
}: headerProps): JSX.Element | undefined => {
	if (isVex) {
		return <Text variant='large'>{t('AssignedVexClusters') as string}</Text>;
	}
	return undefined;
};

export const renderRegulationsHeader = ({
	t,
	isVex = false,
}: headerProps): JSX.Element | undefined => {
	if (isVex) {
		return <Text variant='large'>{t('RegulationsInScope') as string}</Text>;
	}
	return undefined;
};

export const renderRequirementsHeader = ({
	t,
	isVex = false,
}: headerProps): JSX.Element | undefined => {
	if (isVex) {
		return <Text variant='large'>{t('RequirementsInScope') as string}</Text>;
	}
	return undefined;
};

export const useRegulationHeaderRow = (columnKey: string, t: any) => {
	const theme = useTheme();
	const dashboardClassNames = getDashboardClassNames(theme);

	const headers = [];

	headers.push(
		<Text className={dashboardClassNames.regulatoryHeaderCell}>
			{t('RegulationVersion')}
		</Text>,
	);

	if (columnKey !== t('CompletedTitle')) {
		headers.push(
			<Text className={dashboardClassNames.regulatoryHeaderCell}>
				{t('NoOfSegments')}
			</Text>,
		);
	}

	return (
		<div className={dashboardClassNames.regulatoryHeaderRow}>{headers}</div>
	);
};
