/* eslint-disable prettier/prettier */
/* eslint-disable no-negated-condition */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Stack, Label } from '@fluentui/react';
import { getTheme, mergeStyles } from '@fluentui/react/lib/Styling';

const theme = getTheme();
const { palette } = theme;

const containerClass = mergeStyles({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '20px',
  cursor: 'pointer',
  height: '32px',
  width: '180px',
  backgroundColor: palette.neutralLight,
  position: 'relative',
});

const toggleThumbClass = mergeStyles({
  position: 'absolute',
  height: '32px',
  width: '60px',
  backgroundColor: palette.themePrimary,
  borderRadius: '20px',
  transition: 'left 0.3s',
});

const toggleLabelClass = mergeStyles({
  flex: '1 1 33%',
  textAlign: 'center',
  lineHeight: '32px',
  zIndex: 1,
  fontWeight: 'bold',
  cursor: 'pointer',
});

const inlineLabelClass = mergeStyles({
  marginRight: '10px',
  fontWeight: 'bold',
  paddingTop: '10px',
  minWidth: '300px',
});

interface ThreeStateToggleProps {
  label: string;
}

const ThreeStateToggle: React.FC<ThreeStateToggleProps> = ({ label }) => {
  const [state, setState] = useState('no');

  const getThumbPosition = () => {
    switch (state) {
      case 'yes':
        return '0%';
      case 'wait':
        return '33%';
      case 'no':
        return '66%';
      default:
        return '66%';
    }
  };

  return (
    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }} styles={{ root: { paddingTop: '10px' } }}>
      <Label className={inlineLabelClass}>{label}</Label>
      <div className={containerClass}>
        <div
          className={toggleThumbClass}
          style={{ left: getThumbPosition() }}
        />
        <div
          className={mergeStyles(toggleLabelClass, { color: state === 'yes' ? palette.white : palette.neutralDark })}
          onClick={() => setState('yes')}
        >
          Yes
        </div>
        <div
          className={mergeStyles(toggleLabelClass, { color: state === 'wait' ? palette.white : palette.neutralDark })}
          onClick={() => setState('wait')}
        >
          Wait
        </div>
        <div
          className={mergeStyles(toggleLabelClass, { color: state === 'no' ? palette.white : palette.neutralDark })}
          onClick={() => setState('no')}
        >
          No
        </div>
      </div>
    </Stack>
  );
};

ThreeStateToggle.propTypes = {
  label: PropTypes.string.isRequired,
};

export default ThreeStateToggle;
