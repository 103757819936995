import {useTheme, Text, Stack} from '@fluentui/react';
import {useUserContext} from 'authentication/UserContext';
import {useTranslation} from 'react-i18next';
import {VexWorkbenchList} from './components/VexWorkbench/VexWorkbenchList';
import {WorkbenchRequirementsList} from './components/VexWorkbench/WorkbenchRequirementsList';
import {KeywordStatus} from 'types';
import {WorkbenchapprovalList} from './components/ApproverWorkbench/WorkbenchApprovalList';
import {
	BaseWorkBenchComponent,
	getDashboardClassNames,
	MyRecentActivity,
	stackItemStyles,
} from './components/Common/BaseWorkBenchComponent';
import {SummaryComponent} from './components/Common/SummaryComponent';
import React from 'react';
import VkoWorkbenchComponent from './components/VkoWorkbench/VkoWorkbenchComponent';

const DashboardPage = () => {
	const {t} = useTranslation('features/dashboard', {
		keyPrefix: 'DashboardPage',
	});
	const theme = useTheme();
	const classNames = getDashboardClassNames(theme);
	const {
		isVex,
		isVko,
		isAdmin,
		isApprover,
		isVkoExternal,
		isServiceProvider,
		isReader,
	} = useUserContext();

	const [vexWorkbenchItemCount, setVexWorkbenchItemCount] = React.useState<{
		new: number | undefined;
		accepted: number | undefined;
		finalized: number | undefined;
	}>({new: undefined, accepted: undefined, finalized: undefined});
	const [vexRequirementCount, setVexRequirementCount] = React.useState<
		number | undefined
	>(undefined);
	const [approverReviewCount, setapproverReviewCount] = React.useState<
		number | undefined
	>(undefined);
	const [activityItemCount, setActivityItemCount] = React.useState<
		number | undefined
	>(undefined);

	const renderVexWorkbenchLists = () => (
		<>
			<VexWorkbenchList
				halfHeight
				keywordStatus={KeywordStatus.New}
				itemCountLoaded={(count: number) =>
					setVexWorkbenchItemCount({...vexWorkbenchItemCount, new: count})
				}
			/>
			<VexWorkbenchList
				halfHeight
				keywordStatus={KeywordStatus.Accepted}
				itemCountLoaded={(count: number) =>
					setVexWorkbenchItemCount({...vexWorkbenchItemCount, accepted: count})
				}
			/>
			<VexWorkbenchList
				halfHeight
				keywordStatus={KeywordStatus.Finalized}
				itemCountLoaded={(count: number) =>
					setVexWorkbenchItemCount({...vexWorkbenchItemCount, finalized: count})
				}
			/>
		</>
	);

	const isVexWorkbenchEmpty = React.useCallback(() => {
		return (
			vexWorkbenchItemCount.new === 0 &&
			vexWorkbenchItemCount.accepted === 0 &&
			vexWorkbenchItemCount.finalized === 0
		);
	}, [vexWorkbenchItemCount]);

	return (
		<div style={{background: theme.palette.neutralLight}}>
			{isVko && <VkoWorkbenchComponent />}
			{isVex && <SummaryComponent requirementCount={vexRequirementCount} />}
			{isVex && !isVexWorkbenchEmpty() && (
				<Stack horizontalAlign='start' className={classNames.WorkbenchHeader}>
					<Text className={classNames.WorkbenchHeaderText}>
						{t('RegulationsOverviewHeader')}
					</Text>
				</Stack>
			)}
			<Stack horizontal horizontalAlign='start'>
				{isVex || isApprover ? (
					<Stack horizontal styles={{root: {width: '100%'}}} grow={1}>
						{isVex && !isVexWorkbenchEmpty() && renderVexWorkbenchLists()}
					</Stack>
				) : (
					<BaseWorkBenchComponent
						isVkoExternal={isVkoExternal}
						isVko={isVko}
						isAdmin={isAdmin}
						isReader={isReader}
						isServiceProvider={isServiceProvider}
						stackItemStyles={stackItemStyles}
					/>
				)}
			</Stack>

			{isVex && vexRequirementCount !== 0 && (
				<Stack horizontalAlign='start' className={classNames.WorkbenchHeader}>
					<Text className={classNames.WorkbenchHeaderText}>
						{t('ReqsOverviewHeader')}
					</Text>
				</Stack>
			)}
			{isVex && vexRequirementCount !== 0 && (
				<Stack
					horizontal
					horizontalAlign='start'
					styles={{root: {width: '100%', overflowX: 'hidden'}}}
					grow={1}
				>
					<WorkbenchRequirementsList
						halfHeight
						itemCountLoaded={(count: number) => setVexRequirementCount(count)}
					/>
				</Stack>
			)}
			{isApprover && approverReviewCount !== 0 && (
				<Stack horizontalAlign='start' className={classNames.WorkbenchHeader}>
					<Text className={classNames.WorkbenchHeaderText}>
						{t('ApproverOveriewHeader')}
					</Text>
				</Stack>
			)}
			{isApprover && (
				<Stack
					horizontal
					horizontalAlign='start'
					styles={{root: {width: '100%'}}}
					grow={1}
				>
					<WorkbenchapprovalList
						halfHeight
						itemCountLoaded={(count: number) => setapproverReviewCount(count)}
					/>
				</Stack>
			)}
			{isVex && activityItemCount !== 0 && (
				<MyRecentActivity
					itemCountLoaded={(count: number) => setActivityItemCount(count)}
				/>
			)}
		</div>
	);
};

export default DashboardPage;
