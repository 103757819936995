import {MyRequirementsMockup} from 'testUtils/dashboardMockups/MyRequirementsMockup';
import {useGetMyRequirementsQuery} from './myRequirements.generated';
import {KeywordStatus, WorkflowStatus} from 'types';
import {useGetWorkbenchItemsVexQuery} from './workbenchItems.generated';
import {
	WorkkbenchItemsVexMockupNew,
	WorkbenchItemsVexMockupAccepted,
	WorkbenchItemsVexMockupFinalized,
} from 'testUtils/dashboardMockups/WorkbenchItemsVexMockup';

/*
  This Hook is used for local test purposes only. Don't commit this flag set to true!
*/

const IS_DEBUG = false;

export const useDashboardQueries = () => {
	const {data: myReqData, loading: myReqLoading} = useGetMyRequirementsQuery();
	const {data: workbenchItemsNew, loading: loadingWorkbenchItemsNew} =
		useGetWorkbenchItemsVexQuery({
			variables: {
				workflowStatus: [WorkflowStatus.Finalized],
				keywordStatus: KeywordStatus.New,
			},
		});
	const {data: workbenchItemsAccepted, loading: loadingWorkbenchItemsAccepted} =
		useGetWorkbenchItemsVexQuery({
			variables: {
				workflowStatus: [WorkflowStatus.Finalized],
				keywordStatus: KeywordStatus.Accepted,
			},
		});
	const {
		data: workbenchItemsFinalized,
		loading: loadingWorkbenchItemsFinalized,
	} = useGetWorkbenchItemsVexQuery({
		variables: {
			workflowStatus: [WorkflowStatus.Finalized],
			keywordStatus: KeywordStatus.Finalized,
		},
	});

	const getRequirementData = () => {
		if (IS_DEBUG) {
			return {
				loading: false,
				data: MyRequirementsMockup,
			};
		}
		return {
			data: myReqData,
			loading: myReqLoading,
		};
	};

	const getWorkbenchItemsVexQuery = (
		status:
			| KeywordStatus.Finalized
			| KeywordStatus.Accepted
			| KeywordStatus.New,
	) => {
		if (IS_DEBUG) {
			switch (status) {
				case KeywordStatus.Finalized:
					return {
						loading: false,
						data: WorkbenchItemsVexMockupFinalized,
					};
				case KeywordStatus.Accepted:
					return {
						loading: false,
						data: WorkbenchItemsVexMockupAccepted,
					};
				case KeywordStatus.New:
				default:
					return {
						loading: false,
						data: WorkkbenchItemsVexMockupNew,
					};
			}
		}
		switch (status) {
			case KeywordStatus.Finalized:
				return {
					loading: loadingWorkbenchItemsFinalized,
					data: workbenchItemsFinalized,
				};
			case KeywordStatus.Accepted:
				return {
					loading: loadingWorkbenchItemsAccepted,
					data: workbenchItemsAccepted,
				};
			case KeywordStatus.New:
			default:
				return {
					loading: loadingWorkbenchItemsNew,
					data: workbenchItemsNew,
				};
		}
	};

	return {
		myRequirementsReturnData: getRequirementData(),
		getWorkbenchItemsVexQuery,
	};
};
