import {TFunction} from 'i18next';
import {ActionButton, Label, Stack, useTheme} from '@fluentui/react';
import React from 'react';
import {DateTimeFormat, formatDateTime} from 'i18n/localeDateFormat';
import i18n from 'i18n/i18n';
import {RichtextDisplay} from 'components/RichtextDisplay';
import {LoadSpinner} from 'components/LoadWrapper/LoadSpinner';
import {HistoryFieldSidePanel} from './HistoryFieldSidePanel';

export const getHistoryEntryStringHtml = (date: Date, value: string): string =>
	`<b>${formatDateTime(
		date,
		i18n,
		DateTimeFormat.DateTimeMonth,
	)}</b>: ${value}`;

export const HistoryField: React.FC<{
	label: string;
	historyEntries: {date: Date; value: string}[];
	isLoading?: boolean;
	t: TFunction;
}> = ({label, historyEntries, isLoading, t}) => {
	const theme = useTheme();
	const [viewDetails, setViewDetails] = React.useState<boolean>(false);

	const historyExceedsLimit = React.useMemo(() => {
		const historyLength = historyEntries.reduce(
			(acc, currEntry) => acc + currEntry.value.length + 22,
			0,
		);
		return historyLength > 500;
	}, [historyEntries]);

	const contentTrimmed = React.useMemo(() => {
		if (!historyExceedsLimit) {
			return historyEntries.length > 0
				? historyEntries
						.map(entry => getHistoryEntryStringHtml(entry.date, entry.value))
						.join('<br/>')
				: '';
		}

		let currentContentTrimmed = '';
		let i = 0;
		while (i < historyEntries.length) {
			const currentHistoryEntry = historyEntries[i++];
			if (
				currentContentTrimmed.length + currentHistoryEntry.value.length >
				478
			) {
				currentContentTrimmed +=
					getHistoryEntryStringHtml(
						currentHistoryEntry.date,
						currentHistoryEntry.value.substring(
							0,
							478 - currentContentTrimmed.length,
						),
					) + '...';
				break;
			}
			currentContentTrimmed +=
				getHistoryEntryStringHtml(
					currentHistoryEntry.date,
					currentHistoryEntry.value,
				) + '<br/>';
		}
		return currentContentTrimmed;
	}, [historyExceedsLimit, historyEntries]);

	const sidePane = React.useMemo(
		() => (
			<HistoryFieldSidePanel
				historyEntries={historyEntries}
				isOpen={viewDetails}
				label={label}
				onClose={() => setViewDetails(false)}
				t={t}
			/>
		),
		[historyEntries, label, t, viewDetails],
	);

	if (isLoading === true) {
		return (
			<div>
				<Stack>
					<Label>{label}</Label>
					<LoadSpinner
						text={t('ChangeHistoryLoaderLabel')}
						extraStyles={{
							root: {
								position: 'relative',
								left: '80px',
								top: '30px',
							},
						}}
					/>
				</Stack>
			</div>
		);
	}

	if (historyEntries.length === 0)
		return (
			<div>
				<Stack horizontal>
					<Label>{label}</Label>
				</Stack>
				<RichtextDisplay value={t('ChangeHistoryEmptyMessage')} />
			</div>
		);

	return (
		<div>
			<Stack horizontal>
				<Label>{label}</Label>
			</Stack>

			<RichtextDisplay value={contentTrimmed} />
			{historyExceedsLimit && (
				<ActionButton
					styles={{
						root: {
							color: theme.palette.themePrimary,
							textAlign: 'left',
							padding: 0,
						},
						label: {
							margin: 0,
						},
					}}
					text={t('ViewMoreButton')}
					onClick={() => setViewDetails(true)}
				/>
			)}
			{sidePane}
		</div>
	);
};
