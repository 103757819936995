import React from 'react';
import {Stack, mergeStyleSets} from '@fluentui/react';
import {useReports} from './hooks';
import {ReportsList} from './_ReportsList';
import {useTranslation} from 'react-i18next';
import {LoadWrapper} from 'components/LoadWrapper';
import {HeaderForPagesWithEntityPage} from 'components/HeaderForPagesWithEntityPage';
import {REGULATION_FORECAST_LINK} from 'features/RegulatoryDocuments/RegDocDetailsPage/RegDocDetailsPage.constants';

export const ReportsPage = () => {
	const {reports, loading} = useReports();
	const {t} = useTranslation('features/reports', {
		keyPrefix: 'ReportsPage',
	});

	return (
		<div style={{marginLeft: 20, marginTop: 20}}>
			<Stack tokens={{childrenGap: 'l1'}}>
				<HeaderForPagesWithEntityPage>
					<h3 className={reportsPageStyles.headline}>{t('Reports')}</h3>
					<p style={{marginTop: 20}}>
						{t('ReportsSubHeader')}{' '}
						<a href={REGULATION_FORECAST_LINK} target='_blank' rel='noreferrer'>
							SharePoint
						</a>
					</p>
				</HeaderForPagesWithEntityPage>
				<LoadWrapper loading={loading}>
					{reports && <ReportsList items={reports} />}
				</LoadWrapper>
			</Stack>
		</div>
	);
};

const reportsPageStyles = mergeStyleSets({
	dropdown: {
		maxWidth: '400px',
	},
	headline: {
		margin: 0,
	},
});
