import React from 'react';
import {MyRequirementsItems} from './MyRequirementsItems';
import {DashboardCardProps} from './VexWorkbenchItems';

export const WorkbenchRequirementsList: React.FC<DashboardCardProps> = (
	props: DashboardCardProps,
) => {
	const {halfHeight, itemCountLoaded} = props;
	return (
		<MyRequirementsItems
			halfHeight={halfHeight}
			itemCountLoaded={itemCountLoaded}
		/>
	);
};
