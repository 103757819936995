export const RequirementsCollectionName = 'requirements';
export const RegulationsCollectionName = 'regulations';
export const RegulatoryDocumentsCollectionName = 'regulatoryDocuments';
export const ParagraphDocumentsCollectionName = 'segments';

const RequirementEntity = 'Requirement';
const RegulationEntity = 'Regulation';
const RegulatoryDocumentEntity = 'RegulatoryDocument';
const RegulatoryDocumentParagraphEntity = 'RegulatoryDocumentParagraph';

export const getCollectionName = (entity: string) => {
	if (entity === RequirementEntity) {
		return RequirementsCollectionName;
	}
	if (entity === RegulationEntity) {
		return RegulationsCollectionName;
	}
	if (entity === RegulatoryDocumentEntity) {
		return RegulatoryDocumentsCollectionName;
	}
	if (entity === RegulatoryDocumentParagraphEntity) {
		return ParagraphDocumentsCollectionName;
	}
	return '';
};

export const getKeywords = (entity: string, item: any) => {
	if (
		entity === RequirementEntity ||
		entity === RegulatoryDocumentEntity ||
		entity === RegulatoryDocumentParagraphEntity
	) {
		return item?.keywords;
	}
	if (entity === RegulationEntity) {
		return item?.mainKeywords;
	}
};

export const getParentUrl = (question: any): string => {
	const firstParent = question?.targetRecords[0];
	if (!firstParent) return '';
	if (question.targetCollection === RequirementsCollectionName) {
		return `/${RequirementsCollectionName}/${firstParent.entityId}`;
	}
	if (question.targetCollection === RegulationsCollectionName) {
		return `/${RegulationsCollectionName}/${firstParent.entityId}`;
	}
	if (question.targetCollection === RegulatoryDocumentsCollectionName) {
		return `/${RegulationsCollectionName}/${firstParent.parentId}/${firstParent.entityId}/paragraphs`;
	}
	if (question.targetCollection === ParagraphDocumentsCollectionName) {
		return `/${RegulationsCollectionName}/${firstParent.parentId}/paragraphs/${firstParent.entityId}`;
	}
	return '';
};
