import React from 'react';
import {mergeStyleSets, Stack, Theme, useTheme} from '@fluentui/react';
import {useTranslation} from 'react-i18next';
import {FavoritesList} from '../FavoritesList';
import {NewsItemsList} from '../NewsItemsList';
import {ActivityList} from 'components/ActivityList';
import {Text} from '@fluentui/react';
import {DashboardCardProps} from '../VexWorkbench/VexWorkbenchItems';
import {VkoParagraphsWorkbenchItems} from '../VkoWorkbench/VkoParagraphsWorkbenchItems';
import {VkoWorkbenchList} from './WorkbenchList';

type BaseWorkBenchComponentProps = {
	isVkoExternal: boolean;
	isAdmin: boolean;
	isReader: boolean;
	isVko: boolean;
	isServiceProvider: boolean;
	stackItemStyles: any;
};

export const BaseWorkBenchComponent: React.FC<BaseWorkBenchComponentProps> = ({
	isVkoExternal,
	isAdmin,
	isReader,
	isVko,
	isServiceProvider,
	stackItemStyles,
}) => {
	const {t} = useTranslation('features/dashboard', {
		keyPrefix: 'DashboardPage',
	});
	return (
		<>
			{!(isAdmin || isReader) && (
				<Stack grow={1} styles={stackItemStyles}>
					<Stack.Item grow={1}>
						<VkoWorkbenchList halfHeight />
					</Stack.Item>
					{isVko && (
						<Stack.Item grow={1}>
							<VkoParagraphsWorkbenchItems
								title={t('VkoWorkbenchParagraphs')}
								halfHeight
							/>
						</Stack.Item>
					)}
				</Stack>
			)}
			<Stack grow={1} styles={stackItemStyles}>
				{!isVkoExternal && !isServiceProvider && (
					<Stack.Item grow={1}>
						<FavoritesList halfHeight />
					</Stack.Item>
				)}
				<Stack.Item grow={1}>
					<NewsItemsList halfHeight title={'News TR:ACE'} />
				</Stack.Item>
			</Stack>
			<Stack grow={1} styles={stackItemStyles}>
				<Stack.Item grow={1}>
					<ActivityList
						showOpenInNewWindow
						title={t('ActivityLog')}
						halfHeight
					/>
				</Stack.Item>
				<Stack.Item grow={1}>
					<ActivityList
						showOpenInNewWindow
						title={t('RecentActivity')}
						halfHeight
					/>
				</Stack.Item>
			</Stack>
		</>
	);
};

export const MyRecentActivity: React.FC<DashboardCardProps> = (
	props: DashboardCardProps,
) => {
	const theme = useTheme();
	const classNames = getDashboardClassNames(theme);
	const {t} = useTranslation('features/dashboard', {
		keyPrefix: 'DashboardPage',
	});

	return (
		<>
			<Stack horizontalAlign='start' className={classNames.WorkbenchHeader}>
				<Text className={classNames.WorkbenchHeaderText}>
					{t('RecentActivityHeader')}
				</Text>
			</Stack>
			<Stack grow={1} styles={stackItemStyles}>
				<Stack.Item grow={1}>
					<ActivityList
						showOpenInNewWindow
						title={t('RecentActivity')}
						halfHeight
						itemCountLoaded={props.itemCountLoaded}
					/>
				</Stack.Item>
			</Stack>
		</>
	);
};

export const getDashboardClassNames = (theme: Theme) =>
	mergeStyleSets({
		header: {
			marginLeft: 20,
			marginTop: 20,
		},
		WorkbenchHeader: {
			backgroundColor: '#f4f4f4',
			padding: '10px 20px',
			borderBottom: '1px solid #ccc',
		},
		WorkbenchHeaderText: {
			fontSize: 20,
			fontWeight: 'bold',
			color: '#333',
		},
		headerText: {
			color: theme.palette.neutralDark,
			fontWeight: 600,
			fontSize: 18,
			lineHeight: 24,
		},
		container: {
			overflow: 'auto',
			height: '100%',
		},
		text: {
			margin: 20,
			color: theme.palette.neutralDark,
			fontWeight: 600,
			fontSize: 18,
			lineHeight: 24,
		},
		activityContainer: {
			background: theme.palette.white,
			paddingTop: 10,
			margin: 10,
			borderRadius: 15,
		},
		workbench: {
			margin: 20,
			color: theme.palette.neutralDark,
			fontWeight: 600,
			fontSize: 18,
			lineHeight: 24,
		},
		keyword: {
			borderRadius: '10px',
			color: theme.palette.neutralSecondary,
			background: theme.palette.neutralLight,
			fontSize: 12,
			padding: '2px 7px',
			marginRight: 5,
			marginBottom: 10,
		},
		regulatoryHeaderRow: {
			display: 'flex',
			justifyContent: 'space-between',
			padding: '10px 16px',
			borderBottom: `1px solid ${theme.palette.neutralLight}`,
			backgroundColor: theme.palette.white,
		},
		regulatoryHeaderCell: {
			fontWeight: 'bold',
			fontSize: '14px',
			color: theme.palette.neutralPrimary,
		},
		keywordWrapper: {
			display: 'flex !important',
			flexWrap: 'wrap',
		},
	});
export const stackItemStyles = {
	root: {
		width: '33%',
	},
};
