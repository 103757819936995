import {useMemo} from 'react';
import {useQuestionCountsByEntityTypeQuery} from '../Faq.generated';
import {isDevEnvironment, isTestEnvironment} from 'appShell/Layout/Navigation';

export const useQuestionsData = ({data, entityType, parentId}: any) => {
	const {data: questionData} = useQuestionCountsByEntityTypeQuery({
		variables: {entityType, parentId},
	});

	const enhancedData = useMemo(() => {
		return (
			data?.map((elem: any) => {
				if (!(isDevEnvironment() || isTestEnvironment())) {
					return elem;
				}

				const question = questionData?.questionCountsByEntityType.find(
					(q: any) => q.entityId === elem.id,
				);

				return {...elem, question};
			}) ?? []
		);
	}, [data, questionData]);

	return enhancedData;
};
