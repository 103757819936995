import React from 'react';
import {MyReviewItems} from './MyReviewItems';
import {DashboardCardProps} from '..';

export const WorkbenchapprovalList: React.FC<DashboardCardProps> = ({
	halfHeight = false,
	itemCountLoaded,
}) => {
	return (
		<MyReviewItems halfHeight={halfHeight} itemCountLoaded={itemCountLoaded} />
	);
};
