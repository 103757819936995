import {Theme, mergeStyleSets, getFocusStyle} from '@fluentui/react';

export const getListClassNames = (theme: Theme, halfHeight = false) =>
	mergeStyleSets({
		workbench: {
			margin: 20,
			color: theme.palette.neutralDark,
			fontWeight: 600,
			fontSize: 18,
			lineHeight: 24,
		},
		container: {
			overflowX: 'hidden',
			maxHeight: halfHeight ? 'calc((100vh - 220px) / 2)' : 'auto',
			height: halfHeight ? 480 : 'auto',
		},
		itemCell: [
			getFocusStyle(theme, {inset: -1}),
			{
				minHeight: 54,
				padding: 10,
				boxSizing: 'border-box',
				borderBottom: `1px solid ${theme.semanticColors.bodyDivider}`,
				display: 'flex',
				selectors: {
					'&:hover': {
						background: theme.palette.neutralLight,
					},
				},
			},
		],
		itemCellError: [
			{
				backgroundColor: theme.palette.themeLighter,
			},
		],
		itemContent: {
			marginLeft: 10,
			overflow: 'hidden',
			flexGrow: 1,
		},
		itemName: [
			theme.fonts.large,
			{
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
			},
		],
		requirementItemName: {
			color: '#C42929',
			fontWeight: '400',
			fontSize: '14px',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		itemSubtext: {
			fontSize: theme.fonts.small.fontSize,
			color: theme.palette.neutralTertiary,
		},
		activityContainer: {
			background: theme.palette.white,
			margin: 10,
			paddingTop: 10,
			borderRadius: 15,
			height: 480,
		},
		dashboardActivityContainer: {
			background: theme.palette.white,
			margin: 10,
			paddingTop: 10,
			borderRadius: 15,
		},
		itemStatus: {
			fontSize: theme.fonts.small.fontSize,
			color: theme.palette.neutralTertiary,
		},
		itemCount: {
			display: 'inline-flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: 30,
			height: 30,
			borderRadius: '50%',
			backgroundColor: theme.palette.themePrimary,
			color: theme.palette.white,
			fontSize: 18,
			fontWeight: 'bold',
		},
		itemCountInline: {
			borderRadius: 5,
			paddingLeft: 5,
			paddingRight: 5,
			marginRight: 10,
			backgroundColor: theme.palette.neutralLight,
		},
		link: {
			color: theme.palette.themePrimary,
			textDecoration: 'none',
		},
		itemPill: {
			fontSize: theme.fonts.xSmall.fontSize,
		},
		activity: {
			margin: 20,
			color: theme.palette.neutralDark,
			fontWeight: 600,
			fontSize: 18,
			lineHeight: 24,
		},
		iconStyles: {
			paddingTop: 2,
			paddingRight: 20,
			height: 20,
			width: 20,
			fontSize: 20,
		},
	});
