import {GetWorkbenchItemsVexQuery} from 'features/Dashboard/hooks/workbenchItems.generated';
import {WorkflowStatus} from 'types';

const KeywordMockupBoth: {__typename?: 'Keyword'; id: string; name: string}[] =
	[
		{
			id: 'KeywordMockup01',
			name: 'Blue Keyword',
			__typename: 'Keyword',
		},
		{
			id: 'KeywordMockup02',
			name: 'Red Keyword',
			__typename: 'Keyword',
		},
	];

const KeywordMockupRed: {__typename?: 'Keyword'; id: string; name: string}[] = [
	{
		id: 'KeywordMockup02',
		name: 'Red Keyword',
		__typename: 'Keyword',
	},
];

export const WorkkbenchItemsVexMockupNew: GetWorkbenchItemsVexQuery = {
	workbenchRegulatoryDocumentParagraphs: [
		{
			paragraphCount: 3,
			regulatoryDocument: {
				id: 'UmVndWxhdG9yeURvY3VtZW50CmQ2NzBlNDBjY2JkMWRjZWNlYzNlN2MzOGM=',
				name: 'Reg Doc New 01',
				workflow: {
					status: WorkflowStatus.Finalized,
					message: null,
					dueDate: '2024-12-30T11:28:47.831Z',
					dueDateInProcess: '2024-12-02T11:28:47.831Z',
					__typename: 'Workflow',
				},
				regulation: {
					id: 'UmVndWxhdGlvbgpkNjcwZTQwYWViZDFkY2VjZWMzZTdjMzcz',
					name: 'Pablo Regulation 01',
					regulationNumber: 'Pablo Regulation 01',
					__typename: 'Regulation',
				},
				createdAt: '2024-10-15T10:15:40.149Z',
				paragraphs: [
					{
						id: 'UmVndWxhdG9yeURvY3VtZW50UGFyYWdyYXBoCmQ2NzBlNDBkMmJkMWRjZWNlYzNlN2MzYTk=',
						createdAt: '2024-10-15T10:15:46.491Z',
						modifiedAt: '2024-10-21T14:13:09.794Z',
						keywords: [
							{
								id: 'S2V5d29yZApkNjcwZTQwNDBiZDFkY2VjZWMzZTdjMzAw',
								name: 'Red Keyword',
								__typename: 'Keyword',
							},
							{
								id: 'S2V5d29yZApkNjcwZTQwNGViZDFkY2VjZWMzZTdjMzEx',
								name: 'Blue Keyword',
								__typename: 'Keyword',
							},
						],
						__typename: 'RegulatoryDocumentParagraph',
					},
					{
						id: 'UmVndWxhdG9yeURvY3VtZW50UGFyYWdyYXBoCmQ2NzBlNDBkMmJkMWRjZWNlYzNlN2MzYWE=',
						createdAt: '2024-10-15T10:15:46.492Z',
						modifiedAt: '2024-10-21T14:13:09.794Z',
						keywords: KeywordMockupBoth,
						__typename: 'RegulatoryDocumentParagraph',
					},
					{
						id: 'UmVndWxhdG9yeURvY3VtZW50UGFyYWdyYXBoCmQ2NzBlNDBkMmJkMWRjZWNlYzNlN2MzYWI=',
						createdAt: '2024-10-15T10:15:46.492Z',
						modifiedAt: '2024-10-21T14:13:09.794Z',
						keywords: KeywordMockupRed,
						__typename: 'RegulatoryDocumentParagraph',
					},
					{
						id: 'UmVndWxhdG9yeURvY3VtZW50UGFyYWdyYXBoCmQ2NzBlNDBkMmJkMWRjZWNlYzNlN2MzYWM=',
						createdAt: '2024-10-15T10:15:46.492Z',
						modifiedAt: '2024-10-21T14:13:09.794Z',
						keywords: [],
						__typename: 'RegulatoryDocumentParagraph',
					},
				],
				__typename: 'RegulatoryDocument',
			},
			__typename: 'VexWorkbenchItem',
		},
		{
			paragraphCount: 40,
			regulatoryDocument: {
				id: 'UmVndWxhdG9yeURvY3VtZW50CmQ2NzJiNmE5Yjk5N2M0ZGIxYWUxYzE4ZTc=',
				name: 'Pablo Reg Doc Prognoser 02 ',
				workflow: {
					status: WorkflowStatus.Finalized,
					message: null,
					dueDate: '2024-11-21T10:58:09.831Z',
					dueDateInProcess: '2024-11-21T10:58:09.831Z',
					__typename: 'Workflow',
				},
				regulation: {
					id: 'UmVndWxhdGlvbgpkNjcyYjZhNzI5OTdjNGRiMWFlMWMxOGM2',
					name: 'Pablo Regulation 02',
					regulationNumber: 'Pablo Regulation 02',
					__typename: 'Regulation',
				},
				createdAt: '2024-11-06T13:09:47.604Z',
				paragraphs: [
					{
						id: 'UmVndWxhdG9yeURvY3VtZW50UGFyYWdyYXBoCmQ2NzJiNmFhNTk5N2M0ZGIxYWUxYzE4ZmM=',
						createdAt: '2024-11-06T13:09:57.216Z',
						modifiedAt: '2024-12-06T10:41:08.490Z',
						keywords: KeywordMockupBoth,
						__typename: 'RegulatoryDocumentParagraph',
					},
					{
						id: 'UmVndWxhdG9yeURvY3VtZW50UGFyYWdyYXBoCmQ2NzJiNmFhNTk5N2M0ZGIxYWUxYzE5MTI=',
						createdAt: '2024-11-06T13:09:57.754Z',
						modifiedAt: '2024-12-06T10:41:08.490Z',
						keywords: KeywordMockupBoth,
						__typename: 'RegulatoryDocumentParagraph',
					},
					{
						id: 'UmVndWxhdG9yeURvY3VtZW50UGFyYWdyYXBoCmQ2NzJiNmFhNTk5N2M0ZGIxYWUxYzE5MTM=',
						createdAt: '2024-11-06T13:09:57.800Z',
						modifiedAt: '2024-12-06T10:41:08.490Z',
						keywords: KeywordMockupBoth,
						__typename: 'RegulatoryDocumentParagraph',
					},
					{
						id: 'UmVndWxhdG9yeURvY3VtZW50UGFyYWdyYXBoCmQ2NzJiNmFhNTk5N2M0ZGIxYWUxYzE5MTQ=',
						createdAt: '2024-11-06T13:09:57.818Z',
						modifiedAt: '2024-12-06T10:41:08.490Z',
						keywords: KeywordMockupBoth,
						__typename: 'RegulatoryDocumentParagraph',
					},
					{
						id: 'UmVndWxhdG9yeURvY3VtZW50UGFyYWdyYXBoCmQ2NzJiNmFhNTk5N2M0ZGIxYWUxYzE5MTU=',
						createdAt: '2024-11-06T13:09:57.830Z',
						modifiedAt: '2024-12-06T10:41:08.490Z',
						keywords: KeywordMockupBoth,
						__typename: 'RegulatoryDocumentParagraph',
					},
					{
						id: 'UmVndWxhdG9yeURvY3VtZW50UGFyYWdyYXBoCmQ2NzJiNmFhNTk5N2M0ZGIxYWUxYzE5MTY=',
						createdAt: '2024-11-06T13:09:57.842Z',
						modifiedAt: '2024-12-06T10:41:08.490Z',
						keywords: KeywordMockupBoth,
						__typename: 'RegulatoryDocumentParagraph',
					},
				],
				__typename: 'RegulatoryDocument',
			},
			__typename: 'VexWorkbenchItem',
		},
		{
			paragraphCount: 1,
			regulatoryDocument: {
				id: 'UmVndWxhdG9yeURvY3VtZW50CmQ2NzQ1YTA2N2U0MjE3MzdjZDU5MmE5ZTY=',
				name: 'Pablo VKO RegDoc 02',
				workflow: {
					status: WorkflowStatus.Finalized,
					message: null,
					dueDate: '2025-01-21T10:58:09.831Z',
					dueDateInProcess: '2024-12-24T10:58:09.831Z',
					__typename: 'Workflow',
				},
				regulation: {
					id: 'UmVndWxhdGlvbgpkNjc0NTlkNTllNDIxNzM3Y2Q1OTI5ZWYx',
					name: 'Pablo VKO Regulation 04',
					regulationNumber: 'Pablo VKO Regulation 04',
					__typename: 'Regulation',
				},
				createdAt: '2024-11-26T10:18:15.948Z',
				paragraphs: [
					{
						id: 'UmVndWxhdG9yeURvY3VtZW50UGFyYWdyYXBoCmQ2NzQ1YTA4MGU0MjE3MzdjZDU5MmFhMGI=',
						createdAt: '2024-11-26T10:18:40.298Z',
						modifiedAt: '2024-11-26T10:33:11.939Z',
						keywords: [
							{
								id: 'S2V5d29yZApkNjcwZTQwNDBiZDFkY2VjZWMzZTdjMzAw',
								name: 'Red Keyword',
								__typename: 'Keyword',
							},
						],
						__typename: 'RegulatoryDocumentParagraph',
					},
					{
						id: 'UmVndWxhdG9yeURvY3VtZW50UGFyYWdyYXBoCmQ2NzQ1YTA4MGU0MjE3MzdjZDU5MmFhMGM=',
						createdAt: '2024-11-26T10:18:40.307Z',
						modifiedAt: '2024-11-26T10:33:11.939Z',
						keywords: [],
						__typename: 'RegulatoryDocumentParagraph',
					},
					{
						id: 'UmVndWxhdG9yeURvY3VtZW50UGFyYWdyYXBoCmQ2NzQ1YTA4MGU0MjE3MzdjZDU5MmFhMGQ=',
						createdAt: '2024-11-26T10:18:40.337Z',
						modifiedAt: '2024-11-26T10:33:11.939Z',
						keywords: [],
						__typename: 'RegulatoryDocumentParagraph',
					},
					{
						id: 'UmVndWxhdG9yeURvY3VtZW50UGFyYWdyYXBoCmQ2NzQ1YTA4MGU0MjE3MzdjZDU5MmFhMGU=',
						createdAt: '2024-11-26T10:18:40.367Z',
						modifiedAt: '2024-11-26T10:33:11.939Z',
						keywords: [],
						__typename: 'RegulatoryDocumentParagraph',
					},
				],
				__typename: 'RegulatoryDocument',
			},
			__typename: 'VexWorkbenchItem',
		},
	],
};

export const WorkbenchItemsVexMockupAccepted: GetWorkbenchItemsVexQuery = {
	workbenchRegulatoryDocumentParagraphs: [
		{
			paragraphCount: 1,
			regulatoryDocument: {
				id: 'UmVndWxhdG9yeURvY3VtZW50CmQ2NzJiNmE5Yjk5N2M0ZGIxYWUxY123456=',
				name: 'Pablo Reg Doc Accepted 02 ',
				workflow: {
					status: WorkflowStatus.Finalized,
					message: null,
					dueDate: '2024-11-21T10:58:09.831Z',
					dueDateInProcess: '2024-11-21T10:58:09.831Z',
					__typename: 'Workflow',
				},
				regulation: {
					id: 'MockupRegulationId01',
					name: 'Pablo Regulation 02',
					regulationNumber: 'Pablo Regulation 02',
					__typename: 'Regulation',
				},
				createdAt: '2024-11-06T13:09:47.604Z',
				paragraphs: [
					{
						id: 'MockupParagraphId01',
						createdAt: '2024-11-06T13:09:57.216Z',
						modifiedAt: '2024-12-06T10:41:08.490Z',
						keywords: KeywordMockupBoth,
						__typename: 'RegulatoryDocumentParagraph',
					},
					{
						id: 'MockupParagraphId02',
						createdAt: '2024-11-06T13:09:57.754Z',
						modifiedAt: '2024-12-06T10:41:08.490Z',
						keywords: KeywordMockupBoth,
						__typename: 'RegulatoryDocumentParagraph',
					},
					{
						id: 'MockupParagraphId03',
						createdAt: '2024-11-06T13:09:57.800Z',
						modifiedAt: '2024-12-06T10:41:08.490Z',
						keywords: KeywordMockupBoth,
						__typename: 'RegulatoryDocumentParagraph',
					},
					{
						id: 'MockupParagraphId04',
						createdAt: '2024-11-06T13:09:57.818Z',
						modifiedAt: '2024-12-06T10:41:08.490Z',
						keywords: KeywordMockupBoth,
						__typename: 'RegulatoryDocumentParagraph',
					},
					{
						id: 'MockupParagraphId05',
						createdAt: '2024-11-06T13:09:57.830Z',
						modifiedAt: '2024-12-06T10:41:08.490Z',
						keywords: KeywordMockupBoth,
						__typename: 'RegulatoryDocumentParagraph',
					},
				],
				__typename: 'RegulatoryDocument',
			},
			__typename: 'VexWorkbenchItem',
		},
	],
};

export const WorkbenchItemsVexMockupFinalized: GetWorkbenchItemsVexQuery = {
	workbenchRegulatoryDocumentParagraphs: [
		{
			paragraphCount: 3,
			regulatoryDocument: {
				id: 'UmVndWxhdG9yeURvY3VtZW50CmQ2NzBlNDBjY2JkMWRjZWNlYzNlN2MzOGM=',
				name: 'Reg Doc Finalized 03',
				workflow: {
					status: WorkflowStatus.Finalized,
					message: null,
					dueDate: '2024-12-30T11:28:47.831Z',
					dueDateInProcess: '2024-12-02T11:28:47.831Z',
					__typename: 'Workflow',
				},
				regulation: {
					id: 'UmVndWxhdGlvbgpkNjcwZTQwYWViZDFkY2VjZWMzZTdjMzcz',
					name: 'Pablo Regulation 01',
					regulationNumber: 'Pablo Regulation 01',
					__typename: 'Regulation',
				},
				createdAt: '2024-10-15T10:15:40.149Z',
				paragraphs: [
					{
						id: 'UmVndWxhdG9yeURvY3VtZW50UGFyYWdyYXBoCmQ2NzBlNDBkMmJkMWRjZWNlYzNlN2MzYTk=',
						createdAt: '2024-10-15T10:15:46.491Z',
						modifiedAt: '2024-10-21T14:13:09.794Z',
						keywords: KeywordMockupBoth,
						__typename: 'RegulatoryDocumentParagraph',
					},
					{
						id: 'UmVndWxhdG9yeURvY3VtZW50UGFyYWdyYXBoCmQ2NzBlNDBkMmJkMWRjZWNlYzNlN2MzYWE=',
						createdAt: '2024-10-15T10:15:46.492Z',
						modifiedAt: '2024-10-21T14:13:09.794Z',
						keywords: KeywordMockupRed,
						__typename: 'RegulatoryDocumentParagraph',
					},
					{
						id: 'UmVndWxhdG9yeURvY3VtZW50UGFyYWdyYXBoCmQ2NzBlNDBkMmJkMWRjZWNlYzNlN2MzYWI=',
						createdAt: '2024-10-15T10:15:46.492Z',
						modifiedAt: '2024-10-21T14:13:09.794Z',
						keywords: KeywordMockupRed,
						__typename: 'RegulatoryDocumentParagraph',
					},
					{
						id: 'UmVndWxhdG9yeURvY3VtZW50UGFyYWdyYXBoCmQ2NzBlNDBkMmJkMWRjZWNlYzNlN2MzYWM=',
						createdAt: '2024-10-15T10:15:46.492Z',
						modifiedAt: '2024-10-21T14:13:09.794Z',
						keywords: [],
						__typename: 'RegulatoryDocumentParagraph',
					},
					{
						id: 'UmVndWxhdG9yeURvY3VtZW50UGFyYWdyYXBoCmQ2NzBlNDBkMmJkMWRjZWNlYzNlN2MzYWQ=',
						createdAt: '2024-10-15T10:15:46.492Z',
						modifiedAt: '2024-10-21T14:13:09.794Z',
						keywords: [],
						__typename: 'RegulatoryDocumentParagraph',
					},
				],
				__typename: 'RegulatoryDocument',
			},
			__typename: 'VexWorkbenchItem',
		},
	],
};
