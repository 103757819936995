import React from 'react';
import {
	EntityList,
	EntityListColumn,
	EntityListProps,
	RichtextDisplay,
} from 'components';
import {IColumn, Label, mergeStyleSets} from '@fluentui/react';
import {useNavigate} from 'react-router-dom';
import {useTranslation, TFunction} from 'react-i18next';
import {Report} from './hooks';
import {ItemSummaryTooltip} from './_ItemSummaryTooltip';
import {ReportsTooltipTranslationProvider} from './ReportsTooltipTranslationProvider';
import {
	useTooltipTranslation,
	ColumnWithTooltipOptions,
} from 'features/localizedTooltips';
import {
	renderArrayField,
	renderDateString,
	renderDateStringOrDefaultDate,
	renderPhase,
	renderStatus,
} from 'components/EntityList/ColumnRenderers';
import {
	DATE_TYPE,
	DEFAULT_DATE,
	REPORTS_TABLE_NAME,
} from 'components/EntityList/EntityUtils';
import {
	getPhaseFilterKey,
	getPhaseFilterLabel,
} from 'features/RegulatoryDocuments/components/DocumentDetails';
import {useUserContext} from 'authentication/UserContext';
import {WorkflowStatus} from 'types';
import {getHistoryEntryStringHtml as getHistoryEntryHtmlString} from 'components/DetailsSection/HistoryField';
import {CellWithEntityLink} from 'components/EntityList/CellWithEntityLink';

interface Column extends Omit<ColumnWithTooltipOptions, 'minWidth'> {
	key: string;
	name: string;
	isIconOnly?: boolean;
	minWidth?: number;
	maxWidth?: number;
	onRender?: IColumn['onRender'];
	filterable?: boolean;
	sortable?: boolean;
	dataType?: string;
}

const getColumn = ({
	isIconOnly = false,
	minWidth = 90,
	maxWidth = 130,
	filterable = true,
	sortable = true,
	dataType = '',
	key,
	...props
}: Column) => ({
	key,
	fieldName: key,
	isIconOnly,
	minWidth,
	maxWidth,
	filterable,
	sortable,
	dataType,
	...props,
});

const useColumns = (t: TFunction): EntityListColumn[] => {
	const {t: getReportsListTranslation} = useTooltipTranslation(
		'features/Reports/reportsList',
	);

	const {isVko, isAdmin} = useUserContext();

	return [
		getColumn({
			key: 'tooltip',
			name: 'tooltip',
			isIconOnly: true,
			minWidth: 16,
			maxWidth: 16,
			sortable: false,
			filterable: false,
			onRender(report: any) {
				const historyHtmlString = report.changeHistory
					?.map((hitem: any, index: number) => {
						const historyHtml = getHistoryEntryHtmlString(
							new Date(hitem.createdAt),
							hitem.change.value,
						);
						return historyHtml;
					})
					.join('<br/>');
				const summaryString =
					historyHtmlString?.length > 0
						? report.summary + historyHtmlString
						: report.summary;
				return <ItemSummaryTooltip id={report.id} summary={summaryString} />;
			},
			tooltipHostProps: {content: getReportsListTranslation('tooltip')},
		}),
		getColumn({
			key: 'regulationNumber',
			name: t('Regulation'),
			minWidth: 240,
			sortable: true,
			isResizable: true,
			onRender: item => (
				<CellWithEntityLink
					href={`/regulations/${item.regulationId}/${item.id}/paragraphs`}
				>
					<Label
						styles={{
							root: {
								padding: 0,
								fontSize: 16,
								color: 'inherit',
							},
						}}
					>
						{item.regulationNumber}
					</Label>
				</CellWithEntityLink>
			),
		}),
		getColumn({
			key: 'name',
			name: t('Version'),
			minWidth: 200,
			sortable: true,
			isResizable: true,
		}),
		{
			key: 'workflow-status',
			name: t('WorkflowStatus'),
			sortable: true,
			fieldName: 'workflowStatus',
			minWidth: 200,
			onRender: renderStatus('WorkflowStatus'),
			filterable: true,
			hidden: !isVko && !isAdmin,
		},
		getColumn({
			key: 'workflowClearingTimestamp',
			name: t('Created'),
			minWidth: 200,
			sortable: false,
			onRender: renderDateStringOrDefaultDate(), // Filtering was not working without default date
			isResizable: true,
			dataType: DATE_TYPE,
		}),
		getColumn({
			key: 'contactPerson',
			name: t('ContactPerson'),
			minWidth: 160,
			sortable: true,
			onRender(item) {
				return (
					<>
						{item.contactPerson?.map((person: string, i: number) => (
							<div key={i}>{person}</div>
						))}
					</>
				);
			},
			isResizable: true,
		}),
		getColumn({
			key: 'status',
			name: 'Status',
			sortable: true,
			isResizable: true,
		}),
		getColumn({
			key: 'market',
			name: t('Markets'),
			sortable: true,
			minWidth: 200,
			maxWidth: 200,
			onRender: renderArrayField(),
			isResizable: true,
		}),
		getColumn({
			key: 'keywords',
			name: t('MainKeywords'),
			sortable: true,
			minWidth: 200,
			maxWidth: 200,
			onRender: renderArrayField(),
			isResizable: true,
		}),
		getColumn({
			key: 'modelYear',
			name: t('ModelYear'),
			sortable: true,
			isResizable: true,
		}),
		getColumn({
			key: 'dateEffective',
			name: t('DateEffective'),
			sortable: true,
			isResizable: true,
			onRender: renderDateString(),
			dataType: DATE_TYPE,
		}),
		getColumn({
			key: 'dateNewRegistration',
			name: t('DateNewRegistration'),
			sortable: true,
			isResizable: true,
			onRender: renderDateString(),
			dataType: DATE_TYPE,
		}),
		getColumn({
			key: 'dateNewTypes',
			name: t('DateNewTypes'),
			sortable: true,
			isResizable: true,
			onRender: renderDateString(),
			dataType: DATE_TYPE,
		}),
		{
			key: 'p-phaseIn',
			name: t('PhaseIn'),
			sortable: true,
			fieldName: 'phaseIn',
			minWidth: 200,
			getFilterKey: getPhaseFilterKey,
			getFilterLabel: getPhaseFilterLabel,
			onRender: renderPhase(),
			getFieldValue: (report: any) => report?.phaseIn[0]?.date ?? DEFAULT_DATE,
			filterable: false,
			dataType: DATE_TYPE,
		},
		{
			key: 'p-phaseOut',
			name: t('PhaseOut'),
			sortable: true,
			fieldName: 'phaseOut',
			minWidth: 200,
			getFilterKey: getPhaseFilterKey,
			getFilterLabel: getPhaseFilterLabel,
			onRender: renderPhase(),
			getFieldValue: (report: any) => report?.phaseOut[0]?.date ?? DEFAULT_DATE,
			filterable: false,
			dataType: DATE_TYPE,
		},
	];
};

export const ReportsList = ({items}: ReportsListProps) => {
	const navigate = useNavigate();
	const {t} = useTranslation('features/reports', {
		keyPrefix: 'ReportsList',
	});

	const onItemInvoked = (item: Report) => {
		navigate(`/regulations/${item.regulationId}/${item.id}/paragraphs`);
	};

	const {isAdmin, isVko, isVex} = useUserContext();

	const filteredItems = React.useMemo(() => {
		if (isAdmin || isVko) return items;
		if (isVex) {
			return items.filter(
				item => item.workflowStatus === WorkflowStatus.Finalized,
			);
		}
		return items;
	}, [items, isAdmin, isVko, isVex]);

	return (
		<ReportsTooltipTranslationProvider>
			<EntityList
				sticky={true}
				selectionMode={0}
				{...{items: filteredItems, columns: useColumns(t), onItemInvoked}}
				tableId={REPORTS_TABLE_NAME}
			/>
		</ReportsTooltipTranslationProvider>
	);
};

export type ReportsListProps = {
	items: EntityListProps['items'];
};
