import {useGetMyKeywordsLazyQuery} from 'authentication/hooks/myKeywords.generated';
import {useGetMyVexClustersLazyQuery} from 'authentication/hooks/myVexClusters.generated';
import {useEffect} from 'react';
import {useVkoKeywordsLazyQuery} from './vkoKeywords.generated';

export const useAssignmentData = (isVex: boolean, isVko: boolean) => {
	const [getVexKeywords, {loading: loadingVexKeywords, data: vexKeywords}] =
		useGetMyKeywordsLazyQuery();

	const [getVexClusters, {loading: loadingVexClusters, data: vexClusters}] =
		useGetMyVexClustersLazyQuery();

	const [getVkoKeywords, {loading: loadingVkoKeywords, data: vkoKeywords}] =
		useVkoKeywordsLazyQuery();

	useEffect(() => {
		if (isVex) {
			getVexKeywords().catch(error => {
				console.error('Error fetching Vex keywords:', error);
			});
			getVexClusters().catch(error => {
				console.error('Error fetching Vex Clusters:', error);
			});
		} else if (isVko) {
			getVkoKeywords().catch(error => {
				console.error('Error fetching Vko keywords:', error);
			});
		}
	}, [isVex, isVko, getVexKeywords, getVexClusters, getVkoKeywords]);

	return {
		loadingVexKeywords,
		vexKeywords,
		loadingVexClusters,
		vexClusters,
		loadingVkoKeywords,
		vkoKeywords,
	};
};
